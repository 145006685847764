import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    formCheck,
    formFail,
    formSuccess,
    iAmHigherAuthority,
    handleSubmitActions,
    toggleCollapse,
    convertIsoToLocal,
    secondToHourMinSec,
    paginateData,
    sortFunc,
    actionAppearance,
    checkAll, isBannerPresent, showModal, callActionModal, strTimeDifference
} from "../../libs";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";
import {Modal} from "bootstrap";
import BulkDeleteModal from "../component-assets/BulkDeleteModal";
import {Link} from "react-router-dom";
import {CSVLink} from "react-csv";

const Event = (props) => {
    let dData, bData, noteHeaderData, noteCSVHeaderData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    noteHeaderData = [
        {label: "Timestamp", key: "last_modified", align: "center", type: "datetime-utc"},
        {label: "Created By", key: "display_name", align: "center"},
        {label: "Note", key: "note", align: "left"},
    ];

    noteCSVHeaderData = [
        {label: "Event ID", key: "event_id"},
        {label: "Timestamp", key: "last_modified"},
        {label: "Created By", key: "display_name"},
        {label: "Note", key: "note"},
        {label: "Created On", key: "create_time"}
    ];

    // Permission Variables
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Page Variables
    const [eventId, setEventId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabItem, setTabItem] = useState("init");
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [eventDt, setEventDt] = useState("");
    const [acceptDt, setAcceptDt] = useState("");
    const [ackDt, setAckDt] = useState("");
    const [finishDt, setFinishDt] = useState("");
    const [areaName, setAreaName] = useState("");
    const [areaId, setAreaId] = useState(0);
    const [areaType, setAreaType] = useState("");
    const [autoclearDt, setAutoclearDt] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [deviceCat, setDeviceCat] = useState("");
    const [deviceId, setDeviceId] = useState(0);
    const [eventCat, setEventCat] = useState("");
    const [eventType, setEventType] = useState("");
    const [deviceMfg, setDeviceMfg] = useState("");
    const [deviceSerial, setDeviceSerial] = useState("");
    const [responseTime, setResponseTime] = useState("");
    const [residentName, setResidentName] = useState("");
    const [residentId, setResidentId] = useState(0);
    const [acceptBy, setAcceptBy] = useState("");
    const [userId, setUserId] = useState(0);
    const [reason, setReason] = useState("");
    const [svg1, setSVG1] = useState([]);

    // Note Information Variables
    const [noteData, setNoteData] = useState([]);
    const [notesLoaded, setNotesLoaded] = useState(false);
    const [notesStoredIndex, setNotesStoredIndex] = useState(0);
    const [notesIdList, setNotesIdList] = useState([]);
    const [notesTableRows, setNotesTableRows] = useState([]);
    const [dContent, setDContent] = useState("");
    const [notesPage, setNotesPage] = useState(1);
    const [notesPerPage, setNotesPerPage] = useState(100);
    const [prenoteData, setPrenoteData] = useState([]);
    const [prenoteOptions, setPrenoteOptions] = useState([]);
    const [notesPPRules, setNotesPPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    })
    const [notesSorter, setNotesSorter] = useState({
        sortColumn: "create_time",
        sortOrder: "asc"
    });
    const [noteCSVLink, setNoteCSVLink] = useState({
        filename: `${eventId.split("@")[1]}_notes.csv`,
        headers: [],
        data: []
    });
    const [noteCSVButton, setNoteCSVButton] = useState(
        <CSVLink {...noteCSVLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                    class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {event_id: eventId, campus_id: campusId},
                          procedure: "getevent", reqType: "stored"}).then(data => {
                setBasicData(data);
                setBasicLoaded(true);
            });
            {dData?.event_notes_active &&
                ApiRequester({reqEndpoint: "myfetch", payload: {event_id: eventId, campus_id: campusId},
                              procedure: "geteventnotes", reqType: "stored"}).then(data => {
                    setNoteData(data ? data : []);
                    setNotesLoaded(true);
                });
            }
            {dData?.event_notes_active && dData.event_notes_preform &&
                ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_preform_event_notes",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                    setPrenoteData(data);
                });
            }
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let tmp = [];
        if (prenoteData.length > 0){
            for (let i=0; i < prenoteData.length; i++){
                tmp.push(
                    <option key={`prenote-option-${i}`} value={prenoteData[i]?.preform_note}>{prenoteData[i]?.preform_note}</option>
                );
            }
        }
        if (dData?.event_notes_freeform){
            tmp.push(
                <option key={`prenote-option-00`} value={"0"}>Other (freeform note)</option>
            );
        }
        setPrenoteOptions(() => tmp);
    }, [prenoteData]);

    useEffect(() => {
        setNoteCSVLink(prevState => {
            return {...prevState,
                headers: noteCSVHeaderData,
                data: noteData}
        });
    }, [noteData]);

    useEffect(() => {
        setNoteCSVButton(
            <CSVLink {...noteCSVLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                        class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [noteCSVLink]);

    useEffect(() => {
        if (![basicLoaded, notesLoaded].includes(false)){
            setEventDt(basicData[0]?.event_datetime_local ? basicData[0]?.event_datetime_local : "");
            setAcceptDt(basicData[0]?.accept_datetime_local ? basicData[0]?.accept_datetime_local : "");
            setAckDt(basicData[0]?.ack_datetime_local ? basicData[0]?.ack_datetime_local : "");
            setFinishDt(basicData[0]?.finish_datetime_local ? basicData[0]?.finish_datetime_local : "");
            setAreaName(basicData[0]?.area_name ? basicData[0].area_name : "");
            setAreaType(basicData[0]?.area_type_description ? basicData[0].area_type_description : "");
            setDeviceName(basicData[0]?.description ? basicData[0].description : "");
            setDeviceCat(basicData[0]?.device_category_short_name ? basicData[0].device_category_short_name : "");
            setEventCat(basicData[0]?.event_category_name ? basicData[0].event_category_name : "");
            setEventType(basicData[0]?.event_type_name ? basicData[0].event_type_name : "");
            setDeviceMfg(basicData[0]?.manufacture_name ? basicData[0].manufacture_name : "");
            setDeviceSerial(basicData[0]?.manufacture_uuid ? basicData[0].manufacture_uuid : "");
            setResponseTime(basicData[0]?.response_time_raw ? secondToHourMinSec(basicData[0].response_time_raw) : "");
            setResidentName(basicData[0]?.resident_name ? basicData[0].resident_name : "");
            setAcceptBy(basicData[0]?.display_name ? basicData[0].display_name : "N/A");
            setReason(basicData[0]?.reason_code_description ? basicData[0].reason_code_description : "Unknown");
            setDeviceId(basicData[0]?.device_id ? basicData[0].device_id : 0);
            setAreaId(basicData[0]?.area_id ? basicData[0].area_id : 0);
            setResidentId(basicData[0]?.resident_id ? basicData[0].resident_id : 0)
            setPageLoaded(true);
            setTabItem("basic");
        }
    }, [basicLoaded, notesLoaded]);

    useEffect(() => {
        let pRules = paginateData(noteData, notesPage, notesPerPage);
        setNotesPPRules(pRules);
        if (noteData.length > 0) {
            noteData.sort((a, b) => sortFunc(a, b, notesSorter.sortColumn, notesSorter.sortOrder));
        }
        setNotesTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={noteData} checkbox={editRights}
                                     writeAccess={editRights} unk={"a-"} actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                     tableHeaders={noteHeaderData} editModal={editNoteModal} modal={editRights}
                                     parentComponent={"Event"} dData={dData} checkboxlist={notesIdList}
                                     setCheckboxId={(value) => {setNotesIdList(value)}} checkidkey={"note_id"}
                                     tzOffset={parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0)}/>);
    }, [noteData, notesSorter, notesPage, notesPerPage, notesIdList]);

    function addNoteSubmit(){
        let payload, note;
        handleSubmitActions("addNoteSubmit", "loadAddNoteSubmit");
        note = document.getElementById("note").value;
        if (note === "0"){
            note = document.getElementById("note-freeform").value;
        }
        payload = [{event_id: eventId, campus_id: dData.acnt, note: note, modified_by: dData?.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_event_notes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "note_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                setNotesIdList([]);
                formSuccess(`Note added successfully.`, "addNoteSubmit", "loadAddNoteSubmit", "cancelAddNoteBtn", "addNoteForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addNoteSubmit", "loadAddNoteSubmit");
            }
        });
    }

    function editNoteModal(editIndex){
        let dataIndex, modal, pnote = false;
        if (editRights){
            dataIndex = parseInt(editIndex);
            setNotesStoredIndex(dataIndex);
            if (dData?.event_notes_preform){
                for (let i=0; i < prenoteData.length; i++){
                    if (noteData[dataIndex]?.note === prenoteData[i]?.preform_note){
                        document.getElementById("noteE").value = noteData[dataIndex]?.note;
                        pnote = true;
                        break;
                    }
                }
                if (!pnote){
                    document.getElementById("noteE").value = "0";
                    document.getElementById("freeformE").classList.remove("d-none");
                    document.getElementById("noteE-freeform").required = true;
                    document.getElementById("noteE-freeform").value = noteData[dataIndex]?.note;
                }else{
                    document.getElementById("noteE-freeform").value = "";
                    document.getElementById("noteE-freeform").required = false;
                    document.getElementById("freeformE").classList.add("d-none");
                }
            }else{
                document.getElementById("noteE").value = noteData[dataIndex]?.note;
            }
            formCheck("editNoteForm", "editNoteSubmit");
            modal = new Modal(document.getElementById("editnotemodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function editNoteSubmit(){
        let payload, note, noteId;
        handleSubmitActions("editNoteSubmit", "loadEditNoteSubmit");
        noteId = noteData[notesStoredIndex].note_id
        note = document.getElementById("noteE").value;
        if (note === "0"){
            note = document.getElementById("noteE-freeform").value;
        }
        payload = [{note_id: noteId, event_id: eventId, campus_id: dData.acnt, note: note, modified_by: dData?.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_event_notes", Payload: payload, urlType: "POST",
                            User: dData.user, PKey: "note_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                setNotesIdList([]);
                formSuccess(`Note updated successfully.`, "editNoteSubmit", "loadEditNoteSubmit", "cancelEditNoteBtn", "editNoteForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addNoteSubmit", "loadAddNoteSubmit");
            }
        });
    }

    function deleteNotesPerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        if (editRights){
            handleSubmitActions(submitBtn, loadBtn);
            payload = [];
            for (let i=0; i < dContent.length; i++){
                payload.push({note_id: dContent[i][0], modified_by: dData.user});
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_event_notes", Payload: payload, urlType: "DELETE",
                                PKey: "note_id", User: dData.user, Condition: "primary", CampusId: dData.acnt}).then(data => {
                if (data[0]){
                    setCount(0);
                    setNotesIdList([]);
                    formSuccess("Notes deleted.", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn"]);
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const notesTableHeaders = <TableHeaders checkbox={editRights} headerData={noteHeaderData}
                                            checkAll={() => checkAll(notesPPRules.start, notesPPRules.end, notesIdList, setNotesIdList)}
                                            writeAccess={editRights} sortOrder={notesSorter.sortOrder}
                                            checkboxHeaderId={"uCheckHead"}  sortColumn={notesSorter.sortColumn}
                                            sorter={notesSorter} setSorter={setNotesSorter}
                                            modal={editRights} checkboxlist={notesIdList}
                                            actionAppearance={() => actionAppearance(notesPPRules.start, notesPPRules.end, ["deleteBtn"])}
                                            modal_name={""}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    );

    const footer = (
        <div>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Event Profile"} Title={`Event #: ${eventId}`} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-basic"} className={"nav-item"}>
                                <Button text={"Event Details"}
                                        class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("basic")}/>
                            </li>
                            {dData.event_notes_active &&
                                <li key={"set-notes"} className={"nav-item"}>
                                    <Button text={"Notes"} class={tabItem === "notes" ? "nav-link active" : "nav-link"}
                                            onClick={() => setTabItem("notes")}/>
                                </li>
                            }
                        </ul>
                    </div>
                    <div className={tabItem === "init" ? "d-block" : "d-none"}>
                        <h4>Loading event...</h4>
                    </div>
                    <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "1100px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                       onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show text-center"} id={"basic-card"}>
                                <div className={"d-inline-block my-5"} style={{width: "850px"}}>
                                    <h4 className={"text-center w-100"}>Total Response Time: {finishDt ? strTimeDifference(eventDt, finishDt) : strTimeDifference(eventDt, ackDt)}</h4>
                                    <div style={{height: "10px", borderLeft: "2px dashed grey", borderRight: "2px dashed grey"}}>
                                    </div>
                                    <div style={{borderBottom: "2px grey dashed"}}>
                                    </div>
                                    <div style={{height: "10px", borderLeft: "2px dashed grey", borderRight: "2px dashed grey"}}>

                                    </div>
                                </div>
                                <div className={"d-block my-3"}>
                                    <div className={"p-5 visual-box"} id={"edt-box"}>
                                        <h5 className={"text-center"}>Event Datetime</h5>
                                        <h5 className={"text-center"}>{eventDt ? convertIsoToLocal(eventDt) : eventDt}</h5>
                                    </div>
                                    <div className={"mx-1 visual-box-connect"} id={"edt-acpdt-connect"}>
                                    <div className={"h-50 align-top"} style={{borderBottom: "2px grey dashed"}}>
                                            <div style={{maxWidth: "125px"}} className={"pt-3 mx-auto text-center"}>
                                                <h5>Time to Accept</h5>
                                                <h5>{eventDt && acceptDt ? strTimeDifference(eventDt, acceptDt) : "N/A"}</h5>
                                            </div>
                                        </div>
                                        <div className={"h-50"}>

                                        </div>
                                    </div>
                                    <div className={"p-5 visual-box"} id={"acpdt-box"}>
                                        <h5 className={"text-center"}>Accept Datetime</h5>
                                        <h5 className={"text-center"}>{acceptDt ? convertIsoToLocal(acceptDt) : "N/A"}</h5>
                                        <h5 className={"text-center"}>By {acceptBy}</h5>
                                    </div>
                                    <div className={"mx-1 visual-box-connect"} id={"edt-adt-connect"}>
                                        <div className={"h-50 align-top"} style={{borderBottom: "2px grey dashed"}}>
                                            <div style={{maxWidth: "125px"}} className={"pt-3 mx-auto text-center"}>
                                                <h5>Arrival Time</h5>
                                                <h5>{acceptDt && ackDt ? strTimeDifference(acceptDt, ackDt) : "N/A"}</h5>
                                            </div>
                                        </div>
                                        <div className={"h-50"}>

                                        </div>
                                    </div>
                                    <div className={"p-5 visual-box"}>
                                        <h5 className={"text-center"}>Clear Datetime</h5>
                                        <h5 className={"text-center"}>{ackDt ? convertIsoToLocal(ackDt) : "N/A"}</h5>
                                    </div>
                                    <div className={"mx-1 visual-box-connect"}>
                                        <div className={"h-50 align-top"}
                                             style={{borderBottom: "2px grey dashed"}}>
                                            <div style={{maxWidth: "125px"}}
                                                 className={"pt-3 mx-auto text-center"}>
                                                <h5>Service Time</h5>
                                                <h5>{ackDt && finishDt ? strTimeDifference(ackDt, finishDt) : "N/A"}</h5>
                                            </div>
                                        </div>
                                        <div className={"h-50"}>
                                        </div>
                                    </div>
                                    <div className={"p-5 visual-box"}>
                                        <h5 className={"text-center"}>Finish Datetime</h5>
                                        <h5 className={"text-center"}>{finishDt ? convertIsoToLocal(finishDt) : "N/A"}</h5>
                                    </div>
                                </div>
                                <div className={"d-inline-block my-5"}>
                                    <div className={"d-inline-block align-top mx-4"}>
                                        <p className={"d-block text-center"}
                                           style={{minWidth: "115px", textDecorationLine: "underline"}}>Event Category</p>
                                        <p className={"d-block text-center"} id={"ecat-static"}>{eventCat}</p>
                                    </div>
                                    <div className={"d-inline-block align-top mx-4"}>
                                        <p className={"d-block text-center"}
                                           style={{minWidth: "115px", textDecorationLine: "underline"}}>Event Type</p>
                                        <p className={"d-block text-center"} id={"etype-static"}>{eventType}</p>
                                    </div>
                                    <div className={"d-inline-block align-top mx-4"}>
                                        <p className={"d-block text-center"}
                                           style={{minWidth: "115px", textDecorationLine: "underline"}}>Reason</p>
                                        <p className={"d-block text-center"} id={"etype-static"}>{reason}</p>
                                    </div>
                                    <div className={"d-inline-block align-top mx-4"}>
                                        <p className={"d-block text-center"}
                                           style={{minWidth: "115px", textDecorationLine: "underline"}}>Device Info</p>
                                        <p className={"d-block text-center"} id={"etype-static"}>
                                            <Link to={`/profiles/inovonics/${deviceId}`}>{deviceName}</Link>
                                        </p>
                                        <p className={"d-block text-center"}
                                           id={"etype-static"}>Serial# {deviceSerial}</p>
                                    </div>
                                    <div className={"d-inline-block align-top mx-4"}>
                                        <p className={"d-block text-center"}
                                           style={{minWidth: "115px", textDecorationLine: "underline"}}>Location Info</p>
                                        <p className={"d-block text-center"} id={"etype-static"}>
                                            <Link to={`/profiles/area/${areaId}`}>{areaName}</Link>
                                        </p>
                                        <p className={"d-block text-center"} id={"etype-static"}>{areaType}</p>
                                    </div>
                                    <div className={"d-inline-block align-top mx-4"}>
                                        <p className={"d-block text-center"}
                                           style={{minWidth: "115px", textDecorationLine: "underline"}}>Resident Info</p>
                                        <p className={"d-block text-center"} id={"etype-static"}>
                                            <Link to={`/profiles/resident/${residentId}`}>{residentName}</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {dData.event_notes_active && <div className={tabItem === "notes" ? "d-block" : "d-none"}>
                        <div className={"card"}>
                            <div className={"card-body collapse collapse-content show"} id={"notes-card"}>
                                <div className={"d-flex justify-content-between pb-3"}>
                                    <div>

                                    </div>
                                    <div>
                                        {editRights && <Button text={"Delete"} type={"button"}
                                                               class={"btn btn-danger me-2 hide"}
                                                               id={"deleteBtn"} icon={true}
                                                               iconClass={"fe fe-trash-2 me-2"}
                                                               onClick={() => callActionModal(notesPPRules.start, notesPPRules.end, noteData,
                                                                   ["note_id", "display_name"], ["note_id", ""], "",
                                                                   setDContent, "deletenotesmodal", "deleteBulkModalContent",
                                                                   "Are you sure you wish to delete the following records? ")}/>}
                                        {noteCSVButton}
                                        {editRights && <Button text={"Add Note"} type={"button"}
                                                               class={"btn btn-primary ms-2 mt-1"}
                                                               icon={true} iconClass={"fe fe-plus me-2"}
                                                               onClick={() => {
                                                                   document.getElementById("note").value = "";
                                                                   showModal("addnotemodal");
                                                               }}/>}
                                    </div>
                                </div>
                                <table className={"table table-sm table-white table-hover"}>
                                    <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                                    {notesTableHeaders}
                                    </thead>
                                    <tbody id={"tBody"}>
                                    {notesTableRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className={"modal fade"} id={"addnotemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticAddNoteBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticAddNoteBackdropLabel"}>
                                Add Note
                            </h2>
                            <button type={"button"} className={"btn-close"} id={"addNoteClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"addNoteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                {dData?.event_notes_preform
                                    ? <div className={"form-floating d-block"}>
                                        <select className={"form-select form-select my-2"} id={"note"}
                                                required={true}
                                                onChange={(e) => {
                                                    if (e.target.value === "0") {
                                                        document.getElementById("freeform").classList.remove("d-none");
                                                        document.getElementById("note-freeform").required = true;
                                                    } else {
                                                        document.getElementById("note-freeform").required = false;
                                                        document.getElementById("freeform").classList.add("d-none");
                                                    }
                                                    formCheck("addNoteForm", "addNoteSubmit");
                                                }}>
                                            {prenoteOptions}
                                        </select>
                                        <label htmlFor={"note"} className={"form-label"}>Note</label>
                                    </div>
                                    :   <div className={"my-2 form-floating"}>
                                            <textarea className={"form-control pt-5"} id={"note"} required={true}
                                                      style={{height: "125px"}}
                                                      onKeyUp={() => formCheck("addNoteForm", "addNoteSubmit")}>

                                            </textarea>
                                            <label htmlFor={"note"} className={"form-label"}>Note</label>
                                            <div className={"invalid-feedback"}>
                                                Note required
                                            </div>
                                        </div>
                                }
                                <div className={"my-2 form-floating d-none"} id={"freeform"}>
                                    <textarea className={"form-control pt-5"} id={"note-freeform"}
                                              style={{height: "125px"}}
                                              onKeyUp={() => formCheck("addNoteForm", "addNoteSubmit")}>
                                    </textarea>
                                    <label htmlFor={"note-freeform"} className={"form-label"}>Note</label>
                                    <div className={"invalid-feedback"}>
                                        Note required
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddNoteBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddNoteSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addNoteSubmit"}
                                            onClick={() => addNoteSubmit()}>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editnotemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticEditNoteConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticEditNoteConfirmLabel"}>Edit Note</h2>
                            <button type={"button"} className={"btn-close"} id={"editNoteConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"editNoteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                {dData?.event_notes_preform
                                    ? <div className={"form-floating d-block"}>
                                        <select className={"form-select form-select my-2"} id={"noteE"}
                                                required={true}
                                                onChange={(e) => {
                                                    if (e.target.value === "0") {
                                                        document.getElementById("freeformE").classList.remove("d-none");
                                                        document.getElementById("noteE-freeform").required = true;
                                                    } else {
                                                        document.getElementById("noteE-freeform").required = false;
                                                        document.getElementById("freeformE").classList.add("d-none");
                                                    }
                                                    formCheck("editNoteForm", "editNoteSubmit");
                                                }}>
                                            {prenoteOptions}
                                        </select>
                                        <label htmlFor={"noteE"} className={"form-label"}>Note</label>
                                    </div>
                                    : <div className={"my-2 form-floating"}>
                                            <textarea className={"form-control pt-5"} id={"noteE"} required={true}
                                                      style={{height: "125px"}}
                                                      onKeyUp={() => formCheck("editNoteForm", "editNoteSubmit")}>

                                            </textarea>
                                        <label htmlFor={"noteE"} className={"form-label"}>Note</label>
                                        <div className={"invalid-feedback"}>
                                            Note required
                                        </div>
                                    </div>
                                }
                                <div className={"my-2 form-floating d-none"} id={"freeformE"}>
                                    <textarea className={"form-control pt-5"} id={"noteE-freeform"}
                                              style={{height: "125px"}}
                                              onKeyUp={() => formCheck("editNoteForm", "editNoteSubmit")}>
                                    </textarea>
                                    <label htmlFor={"note-freeform"} className={"form-label"}>Note</label>
                                    <div className={"invalid-feedback"}>
                                        Note required
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelEditNoteBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadEditNoteSubmit"}/>
                                <button type={"button"} className={"btn btn-primary ms-3"}
                                        onClick={() => editNoteSubmit()}
                                        id={"editNoteSubmit"}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <BulkDeleteModal
                modalName={"deletenotesmodal"}
                deletePerform={() => deleteNotesPerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", notesPPRules.start, notesPPRules.end)}
                start={notesPPRules.start} end={notesPPRules.end} header={"Delete Notes"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default Event;