import {Link} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {useNavigate} from 'react-router-dom';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import stockPhoto from "../../img/nophoto.png";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {v4 as uuidv4} from "uuid";
import {
    formatPhone,
    formFail,
    handleSubmitActions,
    toggleCollapse,
    formSuccess,
    showModal,
    swapModal,
    formCheckWithElement,
    formClear,
    formCheck,
    formatDateIso,
    getToday,
    hideModal,
    sortFunc,
    setResFilters,
    iAmHigherAuthority,
    convertSectoHHMM,
    HHMMtoLocalTime,
    isBannerPresent, versionSort
} from "../../libs";
import {Chart, registerables} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";

const ResidentProfile = (props) => {
    let dData, bData, startDate, endDate, eventHeaderData, noteHeaderData;

    const navigate = useNavigate();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    eventHeaderData = [
        // {label: "Event Time", key: "event_datetime_local", align: "center", sorts: false,
        //     link: {enabled: true, linkto: "/profiles/event", linkkey: "event_id", type: "datetime"}},
        {label: "Event Start", key: "event_datetime_local", align: "center", type: "datetime"},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false},
        {label: "Device", key: "description", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
        {label: "Location", key: "area_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Reason", key: "reason_code_description", align: "left", sorts: false},
    ];

    noteHeaderData = [
        {label: "Timestamp", key: "create_time", align: "center", type: "datetime-utc", sorts: false},
        {label: "Created By", key: "display_name", align: "center", sorts: false},
        {label: "Note", key: "note", align: "left", sorts: false},
    ]

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [devEditRights, setDevEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Page Variables
    const [residentId, setResidentId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [repCount, setRepCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabItem, setTabItem] = useState("basic");
    const [storedIndex, setStoredIndex] = useState(0);
    const [prenoteData, setPrenoteData] = useState([]);
    const [prenoteOptions, setPrenoteOptions] = useState([]);
    // Basic Information Variables
    const [resBasicData, setResBasicData] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [nickName, setNickName] = useState("");
    const [area, setArea] = useState("");
    const [areaId, setAreaId] = useState("");
    const [locationData, setLocationData] = useState([]);
    const [locationOptions, setLocationOptions] = useState("");
    const [locationSorter, setLocationSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "asc"
    });
    const [imageData, setImageData] = useState("");
    const [isMobileActive, setIsMobileActive] = useState(dData.resMobile);
    const [isActive, setIsActive] = useState(true);
    const [resGroups, setResGroups] = useState([]);
    const [areaGroups, setAreaGroups] = useState([]);
    const [notes, setNotes] = useState("");
    const [ehrId, setEhrId] = useState("");
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [areaLoaded, setAreaLoaded] = useState(false);
    // Device Information Variables
    const [resDeviceData, setResDeviceData] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [assignedRes, setAssignedRes] = useState("");
    const [deviceToRemove, setDeviceToRemove] = useState("");
    const [manufactureData, setManufactureData] = useState([]);
    const [manufactureOptions, setManufactureOptions] = useState([]);
    const [manufactureId, setManufactureId] = useState(0);
    const [accutechDeviceData, setAccutechDeviceData] = useState([]);
    const [palcareDeviceData, setPalcareDeviceData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventTableRows, setEventTableRows] = useState([]);
    const [serialPattern, setSerialPattern] = useState("^[0-9]+$");
    // Device Learn in Variables
    const [listening, setListening] = useState(false); // Flag indicating listening started, stopped
    const [listenEnabled, setListenEnabled] = useState(false); // Flag if listen feature ready or not
    const [listenCount, setListenCount] = useState(60);  // Counter for fetching and toggle listen off
    const [listenData, setListenData] = useState([]);  // Data response of devices heard
    const [listenList, setListenList] = useState([]); // Drop down options for heard devices serial numbers
    const [deviceCatData, setDeviceCatData] = useState([]);  // Device category data used for drop down in learn in
    const [deviceCatOptions, setDeviceCatOptions] = useState([]);  // Device category drop down options for learn in
    const [deviceTypeData, setDeviceTypeData] = useState([]); // Data used for device type drop down in learn in
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]); // Device type drop down options for learn in
    const [deviceCatId, setDeviceCatId] = useState(0);  // Device Category set in learn in
    const [deviceTypeId, setDeviceTypeId] = useState([]);  // Device Type set in learn in
    const [modelId, setModelId] = useState(0);  // Model selected in list
    const [modelOptions, setModelOptions] = useState([]);
    const [msgClass, setMsgClass] = useState(0);
    const [productCode, setProductCode] = useState(0);
    const [marketId, setMarketId] = useState(0);
    const [allProductCodes, setAllProductCodes] = useState([]);
    const [showDeviceList, setShowDeviceList] = useState(false); // Toggles showing drop down serial or not and required validation
    const [listenDevice, setListenDevice] = useState("");
    const [deviceTypeName, setDeviceTypeName] = useState("");
    const [listenPCode, setListenPCode] = useState(0);
    const [useOptions, setUseOptions] = useState([]);
    const [useId, setUseId] = useState(0);
    const [showAlarmBehaviors, setShowAlarmBehaviors] = useState(false);
    const [deviceImg, setDeviceImg] = useState("");
    // Wellness Information Variables
    const [resWellData, setResWellData] = useState([]);
    const [careData, setCareData] = useState([]);
    const [careflow, setCareflow] = useState([]);
    const [rcData, setRCData] = useState([]);
    const [checkin, setCheckin] = useState("");
    const [rounds, setRounds] = useState("");
    const [ehs, setEhs] = useState("");
    const [ehsurl, setEhsUrl] = useState("");
    const [newFileName, setNewFileName] = useState("");
    const [newFile, setNewFile] = useState("");
    const [newFileExt, setNewFileExt] = useState("");
    // Reporting Information Variables
    const [weekData, setWeekData] = useState({});
    const [hourData, setHourData] = useState({});
    const [hourDataLoaded, setHourDataLoaded] = useState(false);
    const [weekDataLoaded, setWeekDataLoaded] = useState(false);
    const [reasonData, setReasonData] = useState({});
    const [reasonRows, setReasonRows] = useState([]);
    const [hourTimer, setHourTimer] = useState(100);
    const [weekTimer, setWeekTimer] = useState(100);
    const [noteTableRows, setNoteTableRows] = useState([]);
    const [noteTableLength, setNoteTableLength] = useState(0);
    // Mobile Variables
    const [cognitoId, setCognitoId] = useState("");
    const [username, setUsername] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [mobileEmail, setMobileEmail] = useState("");

    startDate = formatDateIso(getToday(-28));
    endDate = formatDateIso(getToday());

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId, campus_id: campusId},
                          procedure: "getresidentprofile", reqType: "stored"}).then(data => {
                if (data !== undefined){
                    setResBasicData(data?.length > 0 ? data[0] : {});
                }
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId, campus_id: campusId},
                          procedure: "getresidentdevices", reqType: "stored"}).then(data => {
                setResDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId, campus_id: campusId},
                          procedure: "getresidentwellness", reqType: "stored"}).then(data => {
                setResWellData(data[0]);
            });
            ApiRequester({reqEndpoint: "myfetch",
                          payload: {campus_id: campusId, window_types: "1,2"},
                          procedure: "getwellnesswindows", reqType: "stored"}).then(data => {
                setRCData(data);
            });
            ApiRequester({reqEndpoint: "myfetch",
                          payload: {campus_id: campusId, care_types: "1,4,5", care_event_types: "1"},
                          procedure: "getcareflowwindows", reqType: "stored"}).then(data => {
                setCareData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setLocationData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getresidentdevicecategories", reqType: "stored"}).then(data => {
                setDeviceCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getinovonicsdevicetypes", reqType: "stored"}).then(data => {
                setDeviceTypeData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getaccutechresidentdevices", reqType: "stored"}).then(data => {
                setAccutechDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getpalcareresidentdevices", reqType: "stored"}).then(data => {
                setPalcareDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_manufactures",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setManufactureData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if ([basicLoaded, areaLoaded].every((item) => item === true)){
            setPageLoaded(true);
        }
    }, [basicLoaded, areaLoaded]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setRepCount(repCount => repCount - 1);
        }, 1000);
        if (repCount <= 0){
            {dData?.event_notes_active && dData.event_notes_preform &&
                ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_preform_event_notes",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                    setPrenoteData(data);
                });
            }
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                                campus_id: campusId, resident_filter: residentId.toString()}).then(data => {
                setHourData(data);
                setHourDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardweekchart",
                                campus_id: campusId, resident_filter: residentId.toString()}).then(data => {
                setWeekData(data);
                setWeekDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "eventReasonSummary",
                        campus_id: campusId, start_date: startDate, end_date: endDate,
                        device_category_filter: "", area_type_filter: "", reason_filter: "", user_filter: "",
                        event_type_filter: "", resident_filter: residentId.toString()}).then(data => {
                setReasonData(() => data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, resident_id: residentId.toString()},
                                procedure: "getlimitedevents", reqType: "stored"}).then(data => {
                setEventData(data);
            });
            setRepCount(repCount => 1200);
        }
        return () => clearInterval(interval);
    }, [repCount]);

    useEffect(() => {
        if (resBasicData && Object.keys(resBasicData).length > 0){
            setFirstName(() => resBasicData?.resident_first_name ? resBasicData.resident_first_name : "");
            setLastName(() => resBasicData?.resident_last_name ? resBasicData.resident_last_name : "");
            setPhone(() => resBasicData?.resident_phone ? formatPhone(resBasicData.resident_phone) : "");
            setEmail(() => resBasicData?.resident_email ? resBasicData.resident_email : "");
            setNickName(() => resBasicData?.resident_nickname ? resBasicData.resident_nickname : "");
            setArea(() => resBasicData?.area_name ? resBasicData.area_name : "");
            setAreaId(() => resBasicData?.area_id ? resBasicData.area_id : "");
            setImageData(() => resBasicData?.image_url ? resBasicData?.image_url : "");
            setCognitoId(() => resBasicData?.cognito_id ? resBasicData.cognito_id : "");
            setUsername(() => resBasicData?.username ? resBasicData.username : "");
            setMobilePhone(() => resBasicData?.mobile_phone ? resBasicData.mobile_phone : "");
            setMobileEmail(() => resBasicData?.mobile_email ? resBasicData.mobile_email : "");
            setIsActive(() => resBasicData?.is_active ? resBasicData.is_active : false);
            setNotes(() => resBasicData?.notes ? resBasicData.notes : "");
            setResGroups(() => resBasicData?.groups ? resBasicData.groups : []);
            setAreaGroups(() => resBasicData?.area_groups ? resBasicData.area_groups : []);
            setEhrId(() => resBasicData?.ehr_external_id ? resBasicData.ehr_external_id : "");
            if (editRights && resBasicData?.is_active !== undefined){
                setEditRights(() => resBasicData?.is_active ? resBasicData.is_active : false);
            }
            setBasicLoaded(true);
        }
    }, [resBasicData]);

    useEffect(() => {
        let tmpRflows = [], tmpAflows = [], tmpDflows = [], allFlows;
        if (pageLoaded && careData?.length > 0){
            for (let i=0; i < careData?.length; i++){
                if (careData[i].escalation_type_id === 1){
                    tmpDflows.push(<>
                            <div key={`cfd-1-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                {careData[i].careflow_description}
                            </div>
                            <div key={`cft-1-${i}`} className={"d-inline-block align-top ms-1"}>
                                {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Default)
                            </div>
                        </>);
                }else if (careData[i].escalation_type_id === 4){
                    for (let i2=0; i2 < resGroups?.length; i2++){
                        if (careData[i]?.references.split(",").includes(resGroups[i2]?.resident_group_id?.toString())){
                            tmpRflows.push(<>
                                <div key={`cfd-4-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {careData[i].careflow_description}
                                </div>
                                <div key={`cft-4-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Resident)
                                </div>
                            </>);
                        }
                    }
                }else if (careData[i].escalation_type_id === 5){
                    for (let i3=0; i3 < areaGroups?.length; i3++){
                        if (careData[i]?.references.split(",").includes(areaGroups[i3]?.area_group_id?.toString())){
                            tmpAflows.push(<>
                                <div key={`cfd-5-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {careData[i].careflow_description}
                                </div>
                                <div key={`cft-5-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Area)
                                </div>
                            </>);
                        }
                    }
                }
            }
            allFlows = tmpRflows.concat(tmpAflows, tmpDflows);
            setCareflow(allFlows.map((item, i) => {return <div key={`cf-item-${i}`}>{item}<br/></div>}));
        }
    }, [careData, pageLoaded]);

    useEffect(() => {
        let rWindows = [], aWindows = [];
        if (pageLoaded && rcData?.length > 0){
            for (let i=0; i < rcData?.length; i++){
                if (rcData[i].window_type_id === 1){
                    for (let i2=0; i2 < resGroups?.length; i2++){
                        if (rcData[i]?.resident_group_id_list.split(",").includes(resGroups[i2]?.resident_group_id?.toString())){
                            rWindows.push(<>
                                <div key={`ccd-1-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {rcData[i].description}
                                </div>
                                <div key={`cct-1-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(rcData[i].window_start))} - {HHMMtoLocalTime(convertSectoHHMM(rcData[i].window_end))}
                                </div>
                            </>);
                        }
                    }
                }else if (rcData[i].window_type_id === 2){
                    for (let i3=0; i3 < areaGroups?.length; i3++){
                        if (rcData[i]?.area_group_id_list.split(",").includes(areaGroups[i3]?.area_group_id?.toString())){
                            aWindows.push(<>
                                <div key={`crd-2-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {rcData[i].careflow_description}
                                </div>
                                <div key={`crt-2-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(rcData[i].window_start))} - {HHMMtoLocalTime(convertSectoHHMM(rcData[i].window_end))}
                                </div>
                            </>);
                        }
                    }
                }
            }
            setCheckin(rWindows.map((item, i) => {return <div key={`cc-item-${i}`}>{item}<br/></div>}));
            setRounds(aWindows.map((item, i) => {return <div key={`cr-item-${i}`}>{item}<br/></div>}));
        }
    }, [rcData, pageLoaded]);

    useEffect(() => {
        let tmp = [];
        if (Object.keys(resBasicData).length > 0){
            for (let i = 0; i < resDeviceData?.length; i++){
                if (resDeviceData[i].manufacture_id === 6){
                    tmp.push(
                        <li key={`device-name-${i}`} className={"mt-2"}>
                            {devEditRights ?
                            <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                               onClick={() => {
                                   setDeviceToRemove(() => resDeviceData[i]?.device_id);
                                   showModal("deviceconfirmmodal");
                               }}/> : <></>}
                            <Link to={`/profiles/accutech/${resDeviceData[i].device_id}`}>{resDeviceData[i].device_name} ({resDeviceData[i].manufacture_uuid})</Link>
                        </li>
                    );
                }else if (resDeviceData[i].manufacture_id === 4){
                    tmp.push(
                        <li key={`device-name-${i}`} className={"mt-2"}>
                            {devEditRights ?
                            <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                               onClick={() => {
                                   setDeviceToRemove(() => resDeviceData[i]?.device_id);
                                   showModal("deviceconfirmmodal");
                               }}/> : <></>}
                            {resDeviceData[i].device_name} ({resDeviceData[i].manufacture_uuid})
                        </li>
                    );
                }else{
                    tmp.push(
                        <li key={`device-name-${i}`} className={"mt-2"}>
                            {devEditRights ?
                            <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                               onClick={() => {
                                   setDeviceToRemove(() => resDeviceData[i]?.device_id);
                                   showModal("deviceconfirmmodal");
                               }}/> : <></>}
                            <Link to={`/profiles/inovonics/${resDeviceData[i].device_id}`}>{resDeviceData[i].device_name} ({resDeviceData[i].manufacture_uuid})</Link>
                        </li>
                    );
                }
            }
        }
        setDevices(() => tmp);
    }, [resDeviceData, devEditRights, resBasicData]);

    useEffect(() => {
        let tmp = [];
        if (prenoteData.length > 0){
            for (let i=0; i < prenoteData.length; i++){
                tmp.push(
                    <option key={`prenote-option-${i}`} value={prenoteData[i]?.preform_note}>{prenoteData[i]?.preform_note}</option>
                );
            }
        }
        if (dData?.event_notes_freeform){
            tmp.push(
                <option key={`prenote-option-00`} value={"0"}>Other (freeform note)</option>
            );
        }
        setPrenoteOptions(() => tmp);
    }, [prenoteData]);

    useEffect(() => {
        if (resWellData && Object.keys(resWellData).length > 0){
            setCheckin(() => resWellData?.is_resident_checkin);
            setRounds(() => resWellData?.is_rounding);
            setEhs(() => resWellData?.ehs_provider);
            setEhsUrl(() => resWellData?.ehs_url);
        }
    }, [resWellData]);

    useEffect(() => {
        if (dData?.event_notes_active){
            setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
                                         writeAccess={true} unk={"a-"} modalType={"add"} modalLabel={"Add Note"}
                                         actionAppearance={() => {}} parentComponent={"Resident"}
                                         tableHeaders={eventHeaderData} modal={true} editModal={addNoteModal}
                                         dData={dData} checkboxlist={[]} editModalBadge={"notes"}
                                         setCheckboxId={() => {}} checkidkey={""} />);
        }else {
            setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
                                         writeAccess={true} unk={"a-"} modalType={"add"} modalLabel={"Add Note"}
                                         actionAppearance={() => {}} parentComponent={"Resident"}
                                         tableHeaders={eventHeaderData} modal={false} editModal={() => {}}
                                         dData={dData} checkboxlist={[]} editModalBadge={"notes"}
                                         setCheckboxId={() => {}} checkidkey={""} />);
        }
    }, [eventData]);

    useEffect(() => {
        let i, tmp = [], pOccupancy;
        locationData.sort((a, b) => sortFunc(a, b, locationSorter.sortColumn, locationSorter.sortOrder));
        for (i = 0; i < locationData?.length; i++){
            pOccupancy = locationData[i]?.number_residents ? locationData[i].number_residents : 0;
            if (![undefined, null].includes(pOccupancy) && pOccupancy >= locationData[i].max_occupancy){
                if (areaId.toString() === locationData[i]?.area_id?.toString()){
                    tmp.push(
                        <option key={`location-option-${i}`} value={locationData[i].area_id}>
                            {locationData[i].area_name}
                        </option>
                    );
                }else{
                    tmp.push(
                        <option className={"text-danger"} key={`location-option${i}`} value={locationData[i].area_id}
                                disabled={true}>
                            {locationData[i].area_name} (Full)
                        </option>
                    );
                }
            } else {
                tmp.push(
                    <option key={`location-option-${i}`} value={locationData[i].area_id}>
                        {locationData[i].area_name}
                    </option>
                );
            }
        }
        setLocationOptions(() => tmp);
        setAreaLoaded(true);
    }, [locationData]);

    useEffect(() => {
        let tmp = [];
        let reasons = reasonData?.reasons ? reasonData.reasons : [];
        for (let i=0; i < reasons?.length; i++){
            tmp.push(
                <tr key={`summaryRow-reasons-${i}`}>
                    <td className={"ps-1"}>{reasons[i].reason_name}</td>
                    <td className={"ps-1 text-center"}>{reasons[i].count}</td>
                    <td className={"ps-1 text-center"}>{reasons[i].percent}</td>
                    <td className={"ps-1 text-center"}>{reasons[i]?.avg_response_time}</td>
                </tr>
            );
        }
        setReasonRows(() => tmp);
    }, [reasonData]);

    useEffect(() => {
        let i, tmp = [];
        manufactureData.sort((a, b) => sortFunc(a, b, "manufacture_name", "asc"));
        for (i = 0; i < manufactureData.length; i++){
            tmp.push(
                <option key={`manufacture-option-${manufactureData[i]?.manufacture_id}`}
                        value={manufactureData[i]?.manufacture_id}>{manufactureData[i]?.manufacture_name}
                </option>
            );
        }
        setManufactureOptions(() => tmp);
    }, [manufactureData]);

    useEffect(() => {
        let tmpOptions = []
        if (manufactureId.toString() === "1"){
            for (let i=0; i < deviceCatData.length; i++){
                tmpOptions.push(
                    <option key={`device-category-option-${deviceCatData[i].device_category_id}`}
                            value={deviceCatData[i].device_category_id}>{deviceCatData[i].device_category_short_name}
                    </option>
                );
            }
        }else if (manufactureId.toString() === "6"){
            tmpOptions.push(
                <option key={"device-category-option-1"}
                        value={1}>{"RF Tags"}
                </option>
            );
        }else if (manufactureId.toString() === "4"){
            tmpOptions.push(
                <option key={"device-category-option-1"}
                        value={2}>{"Pendants"}
                </option>
            );
        }
        setDeviceCatOptions(() => tmpOptions);
    }, [deviceCatData, manufactureId]);

    useEffect(() => {
        let i, tmp = [];
        if (manufactureId.toString() === "1"){
            document.getElementById("listen-container-main").classList.remove("d-none");
            deviceTypeData.sort((a, b) => sortFunc(a, b, "device_type_name", "asc"));
            for (i = 0; i < deviceTypeData.length; i++){
                if ((deviceTypeData[i]?.device_category_id.toString() === deviceCatId.toString()) &&
                    (iAmHigherAuthority(80, dData.role_weight) || !deviceTypeData[i]?.is_third_party)){
                    tmp.push(
                        <option key={"device-use-option-"+deviceTypeData[i]?.inovonics_type_id.toString()}
                                value={deviceTypeData[i]?.inovonics_type_id}>{deviceTypeData[i]?.device_type_name}
                        </option>
                    );
                }
            }
        }else if (manufactureId.toString() === "6"){
            document.getElementById("listen-container-main").classList.add("d-none");
            accutechDeviceData.sort((a, b) => sortFunc(a, b, "description", "asc"));
            for (i = 0; i < accutechDeviceData.length; i++){
                if (deviceCatId === 1){
                    tmp.push(
                        <option key={"device-use-option-"+accutechDeviceData[i]?.accutech_device_id.toString()}
                                value={accutechDeviceData[i]?.accutech_device_id}>{accutechDeviceData[i]?.description}
                        </option>
                    );
                }
            }
        }else if (manufactureId.toString() === "4"){
            document.getElementById("listen-container-main").classList.add("d-none");
            palcareDeviceData.sort((a, b) => sortFunc(a, b, "device_type_name", "asc"));
            for (i = 0; i < palcareDeviceData.length; i++){
                if (palcareDeviceData[i]?.device_category_id.toString() === deviceCatId.toString() &&
                    iAmHigherAuthority(80, dData.role_weight)){
                    tmp.push(
                        <option key={`device-use-option-${palcareDeviceData[i]?.device_type_id}`}
                                value={palcareDeviceData[i]?.device_type_id}>{palcareDeviceData[i]?.device_type_name}
                        </option>
                    );
                }
            }
        }else{
            document.getElementById("listen-container-main").classList.add("d-none");
        }
        setDeviceTypeOptions(() => tmp);
    }, [deviceTypeData, deviceCatId, accutechDeviceData, manufactureId, palcareDeviceData]);

    useEffect(() => {
        let tmp = [], models = [], tmp2 = [], uses = [], tmp3 = [], imgstr = "";
        if (manufactureId.toString() === "1"){
            for (let i=0; i < deviceTypeData.length; i++){
                if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                    models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                    uses = deviceTypeData[i]?.uses ? deviceTypeData[i].uses : [];
                    imgstr = deviceTypeData[i]?.image_url ? deviceTypeData[i].image_url : "";
                    break
                }
            }
            for (let i2=0; i2 < models.length; i2++){
                if (i2 === 0){
                    setModelId(models[i2].model_id);
                    setMsgClass(models[i2].message_class);
                    setProductCode(models[i2].product_type_id);
                    setMarketId(models[i2].market_id);
                }
                tmp2.push(models[i2].product_type_id);
                tmp.push(
                    <option key={`device-model-option-${i2}`}
                            value={models[i2]?.model_id}>{models[i2]?.model_name}
                    </option>
                );
            }
            for (let i3=0; i3 < uses.length; i3++){
                if (i3 === 0){
                    setUseId(uses[i3].inovonics_device_use_id);
                    setShowAlarmBehaviors(uses[i3].option_number !== 0);
                }
                if (uses[i3].option_number !== 0){
                    tmp3.push(
                        <div className={"form-check form-switch mt-1"} id={`use-option-${uses[i3].option_number}-container`}>
                            <input className={"form-check-input"} id={`use-option-${uses[i3].option_number}`} type={"radio"}
                                   name={"alarmBehavior"} value={uses[i3].inovonics_device_use_id} defaultChecked={i3 === 0}
                                   onChange={(e) => {setUseId(parseInt(e.target.value))}} />
                            <label className={"form-check-label me-4 mt-1 text-dark"}
                                   htmlFor={`use-option-${uses[i3].option_number}`} id={`use-option-label-${uses[i3].option_number}`}>
                                {uses[i3].option_text}
                            </label>
                        </div>
                    );
                }
            }
        }else if (manufactureId.toString() === "6"){
            setShowAlarmBehaviors(false);
            setModelId(deviceTypeId);
            setUseId(0);
            for (let i=0; i < accutechDeviceData.length; i++){
                if (accutechDeviceData[i]?.accutech_device_id === deviceTypeId){
                    tmp.push(
                        <option key={`device-model-option-${i}`}
                                value={accutechDeviceData[i]?.accutech_device_id}>
                            {accutechDeviceData[i]?.accutech_model_id}
                        </option>
                    );
                }
            }
        }else if (manufactureId.toString() === "4"){
            for (let i=0; i < palcareDeviceData.length; i++){
                if (palcareDeviceData[i]?.device_type_id === deviceTypeId){
                    models = palcareDeviceData[i]?.models ? palcareDeviceData[i].models : [];
                    break
                }
            }
            for (let i2=0; i2 < models.length; i2++){
                if (i2 === 0){
                    setModelId(models[i2].model_id);
                }
                tmp.push(
                    <option key={`device-model-option-${i2}`}
                            value={models[i2]?.model_id}>{models[i2]?.model_name}
                    </option>
                );
            }
        }
        setAllProductCodes(tmp2);
        setModelOptions(() => tmp);
        setUseOptions(() => tmp3);
        setDeviceImg(imgstr);
    }, [deviceTypeData, deviceTypeId, accutechDeviceData, palcareDeviceData]);

    useEffect(() => {
        let models = [];
        if (manufactureId.toString() === "1"){
            for (let i=0; i < deviceTypeData.length; i++){
                if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                    models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                    break
                }
            }
            for (let i2=0; i2 < models.length; i2++){
                if (models[i2].model_id === modelId){
                    setMsgClass(models[i2].message_class);
                    setProductCode(models[i2].product_type_id);
                    setMarketId(models[i2].market_id);
                }
            }
        }
        setListenEnabled(modelId !== 0);
    }, [modelId]);

    useEffect(() => {
        let interval;
        if (checkListenValid()){
            interval = setInterval(() => {
                setListenCount(listenCount  => listenCount  + 1);
            }, 1000);
            if (listenCount < 60){
                if (listenCount % 4 === 0){
                    ApiRequester({reqEndpoint: "getitems", Table: "LearnIn", PKey: "ClientId",
                        PVal: dData.sessionId, CampusId: campusId}).then(data => {
                            setListenData(data);
                    });
                }
            }
            if (listenCount === 60){
                setListening(false);
                toast.info("Capture timeout, restart capture if device not in list.");
            }
        }
        return () => clearInterval(interval);
    }, [listenCount]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [listening, listenDevice]);

    useEffect(() => {
        let tmpList = [];
        for (let i=0; i < listenData.length; i++){
            tmpList.push(
                <option key={`listen-${listenData[i]?.trx_id}`} value={[listenData[i]?.trx_id, listenData[i]?.pType]}>
                    {listenData[i]?.name} ({listenData[i]?.trx_id})
                </option>
            );
        }
        setListenList(tmpList);
        if (listenDevice === "" && listenData.length > 0){
            setListenDevice(() => listenData[0]?.trx_id);
            setListenPCode(() => listenData[0]?.pType);
            toast.success("Devices found.");
        }
    }, [listenData]);

    useEffect(() => {
        if (hourData !== undefined && hourDataLoaded){
            if (document.getElementById("perhour") !== null){
                if (Chart.getChart("perhour") !== undefined){
                    const curChart = Chart.getChart("perhour");
                    curChart.data.labels = hourData?.labels ? hourData.labels : [];
                    curChart.data.datasets[0].data = hourData?.events ? hourData.events : [];
                    curChart.data.datasets[1].data = hourData?.responses ? hourData.responses : [];
                    curChart.options.plugins.annotation.annotations.line1.value = hourData?.avgValue ? hourData.avgValue : 0
                    curChart.options.plugins.annotation.annotations.line2.value = hourData?.avgResp ? hourData.avgResp : 0;
                    curChart.resize();
                    curChart.update();
                }
                else{
                    new Chart('perhour', {
                        options: {
                            responsive: true,
                            plugins: {
                                annotation: {
                                    drawTime: 'afterDatasetsDraw',
                                    annotations: {
                                        line1: {
                                            type: 'line',
                                            borderDash: [8, 4],
                                            borderWidth: 4,
                                            scaleID: "a",
                                            borderColor: "#0081C778",
                                            value: hourData?.avgValue ? hourData.avgValue : 0,
                                            label: {
                                                display: false,
                                                backgroundColor: "#0081C7",
                                                borderColor: "#000000",
                                                borderWidth: 2,
                                                content: `Alarm Average: ${hourData?.avgValue ? hourData.avgValue : 0}`,
                                            },
                                            enter({element}, event) {
                                                element.label.options.display = true;
                                                return true;
                                            },
                                            leave({element}, event) {
                                                element.label.options.display = false;
                                                return true;
                                            }
                                        },
                                        line2: {
                                            type: 'line',
                                            borderDash: [8, 4],
                                            borderWidth: 4,
                                            scaleID: "b",
                                            borderColor: "#BE4D2578",
                                            value: hourData?.avgResp ? hourData.avgResp : 0,
                                            label: {
                                                display: false,
                                                backgroundColor: "#BE4D25",
                                                borderColor: "#000000",
                                                borderWidth: 2,
                                                content: `Response Time Average: ${hourData?.avgResp ? hourData.avgResp : 0}`,
                                            },
                                            enter({element}, event) {
                                                element.label.options.display = true;
                                                return true;
                                            },
                                            leave({element}, event) {
                                                element.label.options.display = false;
                                                return true;
                                            }
                                        }
                                    }
                                },
                                legend: {
                                    display: true
                                },
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: "Time of the Day"
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                                a: {
                                    id: "a",
                                    position: "left",
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: "Total Alarms"
                                    },
                                    suggestedMax: 10,
                                    suggestedMin: 0,
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                        }
                                    }
                                },
                                b: {
                                    id: "b",
                                    position: "right",
                                    title: {
                                        display: true,
                                        text: "Average Response Time"
                                    },
                                    grid: {
                                        display: false
                                    },
                                    suggestedMax: 10,
                                    suggestedMin: 0,
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                        }
                                    }
                                }
                            }
                        },
                        data: {
                            labels: hourData?.labels?.length > 0 ? hourData.labels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                            datasets: [{
                                type: "bar",
                                label: 'Total Alarms',
                                data: hourData?.events ? hourData.events : [],
                                backgroundColor: ["#0081C7"],
                                hoverBackgroundColor: ["#0081C778"],
                                yAxisID: "a",
                                order: 2
                            },{
                                type: "line",
                                label: "Average Response Time",
                                data: hourData?.responses ? hourData.responses : [],
                                backgroundColor: ["#BE4D25"],
                                hoverBackgroundColor: ["#BE4D2578"],
                                borderColor: ["#BE4D2578"],
                                fill: false,
                                yAxisID: "b",
                                order: 1
                            }]
                        }
                    });
                }
            }else{
                setHourTimer((hourTimer) => hourTimer - 1);
            }
        }
    }, [hourData, hourDataLoaded, hourTimer]);

    useEffect(() => {
        if (weekData !== undefined && weekDataLoaded){
            if (document.getElementById("perweek") !== null){
                if (Chart.getChart("perweek") !== undefined){
                    const curChart = Chart.getChart("perweek");
                    curChart.data.labels = weekData?.labels ? weekData.labels : [];
                    curChart.data.datasets[0].data = weekData?.events ? weekData.events : [];
                    curChart.resize();
                    curChart.update();
                }
                else{
                    new Chart('perweek', {
                        options: {
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: true
                                },
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: "Weeks"
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                                a: {
                                    id: "a",
                                    position: "left",
                                    title: {
                                        display: true,
                                        text: "Total Alarms"
                                    },
                                    grid: {
                                        display: false
                                    },
                                    suggestedMax: 10,
                                    suggestedMin: 0,
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                        }
                                    }
                                }
                            }
                        },
                        data: {
                            labels: weekData?.labels?.length > 0 ? weekData.labels : [],
                            datasets: [{
                                type: "line",
                                label: "Total Alarms",
                                data: weekData?.events?.length > 0 ? weekData.events : [],
                                backgroundColor: ["#0081C7"],
                                hoverBackgroundColor: ["#0081C778"],
                                borderColor: ["#0081C778"],
                                fill: false,
                                yAxisID: "a",
                                order: 1
                            }]
                        }
                    });
                }
            }else{
                setWeekTimer((weekTimer) => weekTimer - 1);
            }
        }
    }, [weekData, weekDataLoaded, weekTimer]);

    function checkListenValid(){
        return [listening, manufactureId.toString() === "1", deviceCatId !== 0, deviceTypeId !== 0].every((item) => item === true);
    }

    function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("first-name-edit").classList.toggle("d-none");
        document.getElementById("last-name-edit").classList.toggle("d-none");
        document.getElementById("nickname-static").classList.toggle("d-none");
        document.getElementById("nickname-edit").classList.toggle("d-none");
        document.getElementById("phone-static").classList.toggle("d-none");
        document.getElementById("phone-edit").classList.toggle("d-none");
        document.getElementById("email-static").classList.toggle("d-none");
        document.getElementById("email-edit").classList.toggle("d-none");
        document.getElementById("notes-edit").classList.toggle("d-none");
        document.getElementById("notes-static").classList.toggle("d-none");
        document.getElementById("basic-col2-div").classList.toggle("mx-5");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("first-name-edit").value = firstName;
            document.getElementById("last-name-edit").value = lastName;
            document.getElementById("nickname-edit").value = nickName;
            document.getElementById("phone-edit").value = phone;
            document.getElementById("email-edit").value = email;
            document.getElementById("notes-edit").value = notes;
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let first, last, newphone, newemail, nickname, payload, noteValue, oldPayload;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            first = document.getElementById("first-name-edit").value;
            last = document.getElementById("last-name-edit").value;
            nickname = document.getElementById("nickname-edit").value;
            newphone = document.getElementById("phone-edit").value.replaceAll("-", "");
            newemail = document.getElementById("email-edit").value;
            noteValue = document.getElementById("notes-edit").value;
            payload = [{resident_id: parseInt(residentId), resident_first_name: first, resident_last_name: last,
                        notes: noteValue, resident_email: newemail.toLowerCase(), resident_phone: newphone,
                        resident_nickname: nickname, modified_by: dData.user}];
            oldPayload = [{resident_id: parseInt(residentId), resident_first_name: firstName,
                           resident_last_name: lastName, resident_email: email, resident_phone: phone,
                           resident_nickname: nickName, notes: notes}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", PKey: "resident_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload,
                                CampusId: campusId, OldPayload: oldPayload}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function relocateSubmit(){
        let payload, oldPayload, aId, atext;
        if (editRights){
            handleSubmitActions("relocateSubmit", "loadRelocateSubmit");
            aId = document.getElementById("area-edit").value;
            atext = document.getElementById("area-edit")[document.getElementById("area-edit").selectedIndex].innerText;
            payload = [{resident_id: parseInt(residentId), area_id: parseInt(aId), modified_by: dData.user}];
            oldPayload = [{resident_id: parseInt(residentId), area_id: parseInt(areaId)}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", PKey: "resident_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload,
                                CampusId: campusId, OldPayload: oldPayload}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess(`Resident moved successfully to ${atext}`, "relocateSubmit", "loadRelocateSubmit", "cancelRelocateBtn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "relocateSubmit", "loadRelocateSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function performEhrSubmit(){
        let ehrid, payload, oldpayload;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            ehrid = document.getElementById("ehr-id-edit").value;
            payload = [{resident_id: parseInt(residentId), ehr_external_id: ehrid, modified_by: dData.user}];
            oldpayload = [{resident_id: parseInt(residentId), ehr_external_id: ehrId}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", PKey: "resident_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload,
                                OldPayload: oldpayload, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    handleSubmitActions("ehrSubmit", "loadEhrSubmit");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function handleFileUpload(){
        let file, filereader;
        if (editRights){
            file = document.getElementById('image-upload').files[0];
            setNewFileName(() => file.name);
            setNewFileExt(() => file.name.split(".")[1]);
            filereader = new FileReader();
            filereader.onload = function(event) {
                setNewFile(() => event.target.result);
            }
            filereader.readAsDataURL(file);
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function addNoteModal(rowIndex){
        let dataIndex;
        dataIndex = parseInt(rowIndex);
        setStoredIndex(dataIndex);
        document.getElementById("note").value = "";
        setNoteTableLength(eventData[dataIndex].notes.length);
        setNoteTableRows(<TableBody start={0} end={eventData[dataIndex].notes.length}
                                    tableData={eventData[dataIndex].notes} checkbox={false}
                                     writeAccess={false} unk={"n-"} actionAppearance={() => {}}
                                     tableHeaders={noteHeaderData} modal={false}
                                     parentComponent={"Event"} dData={dData} checkboxlist={[]}
                                     setCheckboxId={() => {}} checkidkey={"note_id"}
                                     tzOffset={parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0)}/>)
        formCheck("addNoteForm", "addNoteSubmit");
        showModal("addnotemodal");
    }

    function addNoteSubmit(){
        let payload, eventId, note;
        handleSubmitActions("addNoteSubmit", "loadAddNoteSubmit");
        eventId = eventData[storedIndex]?.event_id;
        note = document.getElementById("note").value;
        if (note === "0"){
            note = document.getElementById("note-freeform").value;
        }
        payload = [{event_id: eventId, campus_id: dData.acnt, note: note, modified_by: dData?.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_event_notes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "note_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                formSuccess(`Note added successfully.`, "addNoteSubmit", "loadAddNoteSubmit", "cancelAddNoteBtn", "addNoteForm");
                setRepCount(0);
                document.getElementById("submitBtn").click()
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addNoteSubmit", "loadAddNoteSubmit");
            }
        });
    }

    function imageSubmit(){
        let oldURL, oldFilename;
        if (editRights){
            handleSubmitActions("imageUploadSubmit", "loadImageUploadSubmit");

            oldFilename = "";
            if (resBasicData?.image_url){
                oldURL = resBasicData.image_url.split("/");
                oldFilename = oldURL.slice(-1)[0];
            }

            let payload = {image_file: newFile, filename: `${uuidv4()}.${newFileExt}`, resident_id: residentId, campus_id: campusId,
                            oldfilename: oldFilename};
            ApiRequester({reqEndpoint: "mytransaction", TransType: "resident_photo_upload", ClientId: dData.sessionId,
                                Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("File uploaded successfully.", "imageUploadSubmit", "loadImageUploadSubmit", "cancelImageUploadBtn");
                }
                else{
                    formFail(`Error during file upload: ${data[1]}`, "imageUploadSubmit", "loadImageUploadSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function getModelId(){
        let models = [], ret = 0;
        for (let i=0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                break;
            }
        }
        for (let i2=0; i2 < models.length; i2++){
            if (listenPCode === models[i2].product_type_id){
                ret = models[i2].model_id;
            }
        }
        return ret;
    }

    function pendantSubmit(initial){
        let description, msg, payload, serialNum, dRec, mId, oldPayload;
        if (devEditRights){
            if (showDeviceList){
                serialNum = listenDevice;
            }else{
                if (!serialNum || serialNum === ""){
                    serialNum = document.getElementById("serialNum").value;
                }
                if (serialNum && !isNaN(serialNum)){
                    serialNum = parseInt(serialNum);
                }
            }
            if (manufactureId.toString() === "4" && deviceTypeId.toString() === "1"){
                serialNum = serialNum.toString().toLowerCase().replaceAll("-", "").replaceAll(":", "")
            }
            dRec = deviceExists(serialNum);
            if (initial){
                if (dRec && dRec?.is_active){
                    setAssignedRes(dRec?.resident_name);
                    hideModal("pendantmodal");
                    showModal("pendantconfirmmodal");
                    return
                }else{
                    handleSubmitActions("pendantSubmit", "loadPendantSubmit");
                }
            } else{
                handleSubmitActions("pendantConfirmSubmit", "loadPendantConfirmSubmit");
            }

            if (listening){
                setListening(false);
                setListenCount(60);
            }

            description = document.getElementById("description").value;
            mId = modelId;
            if (!iAmHigherAuthority(80, dData.role_weight) && showDeviceList){
                mId = getModelId(listenPCode);
            }

            if (dRec){
                // Edit device and add to resident
                payload = [{device_id: dRec.device_id, campus_id: campusId, is_active: 1, resident_id: residentId,
                            device_name: deviceTypeName, manufacture_model_id: parseInt(mId), device_use_id: parseInt(useId),
                            descriptions: description, modified_by: dData.user, device_type_id: deviceTypeId}];
                oldPayload = [{device_id: dRec.device_id, campus_id: dRec?.campus_id, is_active: dRec?.is_active,
                               resident_id: dRec?.resident_id, device_name: dRec?.device_name,
                               manufacture_model_id: dRec?.manufacture_model_id, device_use_id: dRec?.device_use_id,
                               descriptions: dRec?.descriptions, is_resident: dRec?.is_resident,
                               is_area_device: dRec?.is_area_device, area_id: dRec?.area_id, device_type_id: dRec?.device_type_id}];
                ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload,
                                OldPayload: oldPayload, CampusId: campusId}).then(data => {
                    if (data && data[0] === true){
                        msg = `${deviceTypeName} has been added to ${firstName} ${lastName}`;
                        if (!initial){
                            handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                            handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                            document.getElementById("pendantForm").reset();
                            document.getElementById("pendantConfirmClose").click();
                            toast.success(msg);
                            setCount(0);
                            setShowDeviceList(false);
                        }else{
                            setCount(0);
                            formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                        }
                    }
                    else{
                        toast.error(`Failed to add ${deviceTypeName}`);
                    }
                });
            }else{
                // Add device and add to resident
                payload = [{campus_id: campusId, manufacture_id: parseInt(manufactureId),
                            manufacture_uuid: serialNum.toString(), device_name: deviceTypeName,
                            device_use_id: parseInt(useId), is_active: true, is_alerting_now: false, is_server: false,
                            is_area_device: false, is_resident: true, resident_id: residentId, manufacture_model_id: parseInt(mId),
                            escalation_enabled: true, careflow_id: 1, descriptions: description,
                            modified_by: dData.user, device_type_id: deviceTypeId}];
                ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "PUT",
                                    CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                    if (data && data[0] === true){
                        msg = `${deviceTypeName} has been added to ${firstName} ${lastName}`;
                        formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                        setCount(0);
                        setShowDeviceList(false);
                    }
                    else{
                        toast.error(`Failed to add ${deviceTypeName}`);
                        handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                    }
                });
            }
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deviceExists(serialNum){
        for (let i=0; i < deviceData?.length; i++){
            if (serialNum.toString() === deviceData[i]?.manufacture_uuid.toString()) {
                return deviceData[i];
            }
        }
        return false;
    }

    function publishListen(){
        let sendPtype, modelStuff;
        if (dData.serverVersion === "" || versionSort(dData.serverVersion, "4.04.14") >= 0){
            sendPtype = iAmHigherAuthority(80, dData.role_weight) ? [productCode] : allProductCodes;
            modelStuff = iAmHigherAuthority(80, dData.role_weight) ? `Specific ${productCode}` : "All codes";
        }else{
            sendPtype = productCode;
            modelStuff = productCode;
        }
        ApiRequester({reqEndpoint: "mqtt", Operation: "learnin", ClientId: dData.sessionId,
                            ProductType: sendPtype, CampusId: dData?.acnt, MsgClass: msgClass}).then(data => {
            if (data[0]){
                toast.info(`Listening for ${deviceTypeName} devices, Model Code: ${modelStuff}`);
                setListenCount(0);
            }
            else{
                toast.error("Error with auto listening, try again, " +
                    "if persist contact your customer success for assistance.");
                setListening(false);
                setShowDeviceList(false);
            }
        });
    }

    function addMobileSubmit(){
        // User add form submit function
        let roomPh, payload, memail, msg, newUsername, passwd;
        if (editRights){
            handleSubmitActions("addMobileSubmit", "loadAddMobileSubmit");
            memail = document.getElementById("mobile-email-add").value;
            roomPh = document.getElementById("mobile-phone-add").value.replaceAll("-", "");
            newUsername = document.getElementById("username").value;
            passwd = document.getElementById("password").value;
            payload = [{Action: "new", UserEmail: memail.toLowerCase(), Username: newUsername, UserPass: passwd, CampusId: campusId,
                        ClientId: dData.sessionId, PhoneNumber: roomPh, RegisterType: "resident", resident_id: residentId}];
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    setCount(0);
                    formSuccess(`${firstName} ${lastName} has been signed up for the PalCare resident mobile app.`,
                        "addMobileSubmit", "loadAddMobileSubmit", "cancelAddMobileBtn", "addMobileForm");
                }
                else{
                    if (data[1] === "1"){
                        msg = "Username already exists, please change username.";
                    }
                    else if (data[1] === "2"){
                        msg = "Error, user created, failed saving user details. Contact your customer success manager for assistance.";
                    }
                    else if (data[1] === "8"){
                        msg = "Request aborted, missing required data. Contact your customer success manager for assistance.";
                    }
                    else if (data[1] === "9"){
                        msg = "Request aborted, no matching action. Contact your customer success manager for assistance.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, "addMobileSubmit", "loadAddMobileSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function removeDevice(deviceId){
        let payload;
        if (devEditRights){
            handleSubmitActions("deviceConfirmSubmit", "loadDeviceConfirmSubmit");
            payload = [{device_id: deviceId, is_active: false, resident_id: null, modified_by: dData.user}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    formSuccess("Device has been removed", "deviceConfirmSubmit", "loadDeviceConfirmSubmit", "deviceCancelBtn");
                }
                else{
                    formFail("Failed to remove device", "deviceConfirmSubmit", "loadDeviceConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function passSubmit(){
        let password, username, msg, payload;
        if (editRights){
            handleSubmitActions("passSubmit", "loadPassSubmit");
            password = document.getElementById("passwordR").value;
            payload = [{Action: "reset", CampusId: campusId, Username: username, UserPass: password, RegisterType: "resident"}];
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    setCount(0);
                    formSuccess("Password reset successfully.", "passSubmit", "loadPassSubmit",
                        "cancelPassBtn", "passForm");
                }
                else{
                    if (data[1] === "3"){
                        msg = "Current password does not match records.";
                    }
                    else if (data[1] === "4"){
                        msg = "User not authorized.";
                    }
                    else if (data[1] === "5"){
                        msg = "Error encountered authenticating user. Please contact support for assistance if error persists.";
                    }
                    else if (data[1] === "6"){
                        msg = "New password failed to be set, check password and try again. Contact support if failure persists.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, "passSubmit", "loadPassSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function mobileDeleteSubmit(deviceId){
        let payload
        if (editRights){
            handleSubmitActions("mobileConfirmSubmit", "loadMobileConfirmSubmit");
            payload = [{resident_id: residentId, modified_by: dData.user}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident_mobile", Payload: payload, urlType: "DELETE",
                                PKey: "resident_id", Condition: "primary", CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    formSuccess(`${username} no longer has access to the resident mobile app`, "mobileConfirmSubmit", "loadMobileConfirmSubmit", "mobileCancelBtn");
                }
                else{
                    formFail("Account failed to be removed, contact support if error persists", "mobileConfirmSubmit", "loadMobileConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deletePerform(){
        let payload;
        if (deleteRights){
            handleSubmitActions("deleteConfirmSubmit", "loadDeleteConfirmSubmit");
            for (let i=0; i < resDeviceData.length; i++){
                removeDevice(resDeviceData[i].device_id);
            }
            payload = [{resident_id: parseInt(residentId), modified_by: dData.user}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", Payload: payload, urlType: "DELETE", User: dData.user,
                                PKey: "resident_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    document.getElementById("deleteConfirmCancelBtn").click();
                    navigate("/community/residents", {state: {message: {success: `${firstName} ${lastName} has been deleted.`}}});
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, "deleteConfirmSubmit", "loadDeleteConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function moveOutPerform(){
        let payload, msg, oldPayload;
        if (deleteRights){
            handleSubmitActions("moveOutConfirmSubmit", "loadMoveOutConfirmSubmit");
            for (let i=0; i < resDeviceData.length; i++){
                removeDevice(resDeviceData[i].device_id);
            }
            payload = [{resident_id: parseInt(residentId), area_id: 0, is_active: 0, modified_by: dData.user}];
            oldPayload = [{resident_id: parseInt(residentId), area_id: areaId, is_active: 1}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "resident_id", Condition: "primary", OldPayload: oldPayload, CampusId: campusId,
                                ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    msg = "Resident has been marked in-active"
                    formSuccess(msg, "moveOutConfirmSubmit", "loadMoveOutConfirmSubmit",
                        "moveOutConfirmCancelBtn");
                    setCount(0);
                }
                else{
                    msg = `Error during delete, error code: ${data[1]}`;
                    formFail(msg, "moveOutConfirmSubmit", "loadMoveOutConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function moveInPerform(){
        let payload, msg, aId, oldPayload;
        if (addRights){
            handleSubmitActions("moveInConfirmSubmit", "loadMoveInConfirmSubmit");
            aId = document.getElementById("locationMoveIn")?.value;
            payload = [{resident_id: parseInt(residentId), area_id: aId !== undefined ? parseInt(aId) : 0, is_active: 1, modified_by: dData.user}];
            oldPayload = [{resident_id: residentId, area_id: areaId, is_active: 0}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "resident_id", Condition: "primary", OldPayload: oldPayload, CampusId: campusId,
                                ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    msg = "Resident has been marked in-active";
                    formSuccess(msg, "moveInConfirmSubmit", "loadMoveInConfirmSubmit",
                        "moveInConfirmCancelBtn");
                    setCount(0);
                }
                else{
                    msg = `Error during delete, error code: ${data[1]}`
                    formFail(msg, "moveInConfirmSubmit", "loadMoveInConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const eventTableHeaders = <TableHeaders checkbox={false} headerData={eventHeaderData} checkAll={() => {}}
                                            writeAccess={true} sortOrder={""}
                                            checkboxHeaderId={"uCheckHead"} sortColumn={""} sorter={{}}
                                            setSorter={() => {}} modal={false} modal_name={"Notes"} checkboxlist={[]}
                                            actionAppearance={() => {}}/>
    const notesTableHeaders = <TableHeaders checkbox={false} headerData={noteHeaderData}
                                            checkAll={() => {}} writeAccess={false} sortOrder={"asc"}
                                            sortColumn={"create_time"} sorter={{}}
                                            setSorter={() => {}} modal={false} checkboxlist={[]}
                                            actionAppearance={() => {}}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div></div>
            {pageLoaded ? <div>
                {isActive
                    ? <>
                        {editRights &&
                            <Button text={"Relocate Resident"} class={"btn btn-primary me-3"} id={"relocateBtn"}
                             style={{marginTop: "1.1rem"}} onClick={() => {
                                  document.getElementById("area-edit").value = areaId
                                  showModal("relocatemodal");
                            }} />
                        }
                        {deleteRights &&
                            <Button text={"Move Out"} class={"btn btn-danger me-3"} id={"moveOutBtn"}
                             style={{marginTop: "1.1rem"}} onClick={() => {
                                showModal("moveoutconfirmmodal");
                             }} />
                        }
                      </>
                    : <>
                        {addRights &&
                            <Button text={"Move Back In"} class={"btn btn-primary me-3"} id={"moveOutBtn"}
                             style={{marginTop: "1.1rem"}} onClick={() => {
                                 formCheck("moveInForm", "moveInConfirmSubmit");
                                 showModal("moveinconfirmmodal");
                             }} />
                        }
                        {deleteRights && iAmHigherAuthority(20, dData?.role_weight) &&
                            <Button text={"Delete"} class={"btn btn-danger"} id={"deleteBtn"}
                                style={{marginTop: "1.1rem"}} onClick={() => {
                                showModal("deleteconfirmmodal");
                            }} />
                        }
                      </>
                }
            </div>
            : <></>}
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Resident Profile"} Title={`${firstName} ${lastName}`} content={headerContent}/>
                {pageLoaded
                    ?
                    <div className={"container-fluid"}>
                        <div className={"col"}>
                            <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                <li key={"set-basic"} className={"nav-item"}>
                                    <Button text={"Basic Info"}
                                            class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                            onClick={() => setTabItem("basic")}/>
                                </li>
                                <li key={"set-devices"} className={"nav-item"}>
                                    <Button text={"Devices"}
                                            class={tabItem === "devices" ? "nav-link active" : "nav-link"}
                                            onClick={() => setTabItem("devices")}/>
                                </li>
                                <li key={"set-reporting"} className={"nav-item"}>
                                    <Button text={"Reporting"}
                                            class={tabItem === "reporting" ? "nav-link active" : "nav-link"}
                                            onClick={() => setTabItem("reporting")}/>
                                </li>
                                <li key={"set-wellness"} className={"nav-item"}>
                                    <Button text={"Wellness"}
                                            class={tabItem === "wellness" ? "nav-link active" : "nav-link"}
                                            onClick={() => setTabItem("wellness")}/>
                                </li>
                                {isMobileActive
                                    ? <li key={"set-mobile"} className={"nav-item"}>
                                        <Button text={"Mobile App"}
                                                class={tabItem === "mobile" ? "nav-link active" : "nav-link"}
                                                onClick={() => setTabItem("mobile")}/>
                                    </li>
                                    : <></>
                                }
                            </ul>
                        </div>
                        <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                            <div className={"card"} style={{minWidth: "550px"}}>
                                <div className={"card-header d-flex justify-content-between"}>
                                    <div>
                                        <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                        {!isActive &&
                                            <h3 className={"text-danger d-inline-block ms-3 mb-0"}>- Moved Out</h3>}
                                    </div>
                                    <div>
                                        <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                           onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                    </div>
                                </div>
                                <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                                    <div className={"text-center d-inline-block align-top p-2"}
                                         style={{borderRadius: "7px", backgroundColor: "#f9fbfd"}}>
                                        <img style={{borderRadius: "12px"}} className={"align-top d-block"}
                                             width={"150px"}
                                             height={"150px"}
                                             src={imageData ? imageData : stockPhoto} alt={"Profile Headshot"}/>
                                        {imageData
                                            ? <Button class={"mt-2 btn btn-outline-primary white"} text={"Update Image"}
                                                      title={"Upload Image"} disabled={!editRights}
                                                      onClick={() => {
                                                          if (editRights) {
                                                              showModal("imageuploadmodal");
                                                              document.getElementById("image-upload").click();
                                                          }
                                                      }}/>
                                            : <Button class={"mt-2 btn btn-outline-primary white"} text={"Add Image"}
                                                      title={"Upload Image"}
                                                      onClick={() => {
                                                          if (editRights) {
                                                              showModal("imageuploadmodal");
                                                              document.getElementById("image-upload").click();
                                                          }
                                                      }} disabled={!editRights}/>}
                                        <input className={"d-none"} id={"image-upload"} type={"file"} accept={"image/*"}
                                               onChange={() => handleFileUpload()}/>
                                    </div>
                                    <form className={"d-inline align-top form-control-plaintext was-validated"}
                                          id={"basic-form"}>
                                        <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "75px"}}>Name:</p>
                                                <p className={"ms-4"} id={"name-static"}>{firstName} {lastName}</p>
                                                <input className={"ms-4 d-none form-control form-control-sm"}
                                                       required={true}
                                                       style={{height: "30px", width: "125px"}} id={"first-name-edit"}
                                                       defaultValue={firstName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                                <input className={"ms-2 d-none form-control form-control-sm"}
                                                       required={true}
                                                       style={{height: "30px", width: "125px"}} id={"last-name-edit"}
                                                       defaultValue={lastName}
                                                       onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                                       pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                            </div>
                                            <div className={"mt-2 d-flex"}>
                                                <p style={{minWidth: "75px"}}>Nickname:</p>
                                                <p className={"ms-4"} id={"nickname-static"}>{nickName}</p>
                                                <input className={"ms-4 d-none form-control form-control-sm"}
                                                       style={{height: "30px", width: "256px"}} id={"nickname-edit"}
                                                       defaultValue={nickName}
                                                       onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                                       pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                            </div>
                                            <div className={"mt-2 d-flex"}>
                                                <p style={{minWidth: "75px"}}>Phone:</p>
                                                <p className={"ms-4"} id={"phone-static"}>{phone}</p>
                                                <input className={"ms-4 d-none form-control form-control-sm"}
                                                       style={{height: "30px", width: "256px"}} id={"phone-edit"}
                                                       defaultValue={phone}
                                                       onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                                       pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}/>
                                            </div>
                                            <div className={"mt-2 d-flex"}>
                                                <p style={{minWidth: "75px"}}>Email:</p>
                                                <p className={"ms-4"} id={"email-static"}>{email}</p>
                                                <input className={"ms-4 d-none form-control form-control-sm"}
                                                       style={{height: "30px", width: "256px"}} id={"email-edit"}
                                                       defaultValue={email}
                                                       onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                                       pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                            </div>
                                            <div className={"mt-2 d-flex"}>
                                                <p style={{minWidth: "75px"}}>Area:</p>
                                                <p className={"ms-4"} id={"area-static"}>
                                                    <Link to={`/profiles/area/${areaId}`}>{area}</Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className={"mx-5 mt-2 d-inline-block align-top"} id={"basic-col2-div"}
                                             style={{minWidth: "300px"}}>
                                            <div className={"mt-2 d-flex"}>
                                                <ul className={"ps-0 d-inline-block align-top mb-0"}
                                                    style={{listStyle: "none"}}>
                                                    <li><p style={{minWidth: "75px"}} className={"me-4"}>Groups:</p>
                                                    </li>
                                                </ul>
                                                <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                                    {resGroups?.map((group, i) => {
                                                        return <li
                                                            key={`resident-group-name-item-${i}`}>{group?.resident_group_name}</li>
                                                    })}
                                                </ul>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "75px"}}>Notes:</p>
                                                <p className={"ms-4"} style={{maxWidth: "250px"}}
                                                   id={"notes-static"}>{notes}</p>
                                                <textarea className={"ms-4 d-none form-control form-control-sm"}
                                                          maxLength={2200}
                                                          style={{height: "auto", width: "250px", maxHeight: "300px"}}
                                                          id={"notes-edit"}
                                                          defaultValue={notes}
                                                          onKeyUp={() => formCheck("basic-form", "save-basic-btn")}/>
                                            </div>
                                        </div>
                                    </form>
                                    {pageLoaded && editRights &&
                                        <>
                                            <Button text={"Edit"} class={"btn btn-primary float-end"}
                                                    id={"edit-basic-btn"}
                                                    disabled={!editRights} onClick={() => {
                                                if (editRights) {
                                                    basicActionHandling()
                                                }
                                            }}/>
                                            <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"}
                                                    id={"save-basic-btn"}
                                                    disabled={!editRights} onClick={() => {
                                                if (editRights) {
                                                    basicSubmit()
                                                }
                                            }}/>
                                            <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"}
                                                    disabled={true} spinner={true} text={""} id={"load-basic-btn"}/>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={tabItem === "devices" ? "d-block" : "d-none"}>
                            <div className={"card"} style={{minWidth: "500px"}}>
                                <div className={"card-header d-flex justify-content-between"}>
                                    <div>
                                        <h3 className={"card-header-title d-inline-block"}
                                            style={{minWidth: "80px"}}>Devices</h3>
                                        <div className={"d-none"} style={{display: "inline-block"}}
                                             id={"devices-header-data"}>
                                            <h3 className={"ms-6 my-0 d-inline-block text-success"}>{devices.length} {devices?.length === 1 ? "Device" : "Devices"}</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"devices-card-btn"}
                                           onClick={() => {
                                               toggleCollapse("devices-card-btn", "devices-card");
                                               document.getElementById("devices-header-data").classList.toggle("d-none");
                                           }}></i>
                                    </div>
                                </div>
                                <div className={"card-body collapse collapse-content show"} id={"devices-card"}>
                                    <ul className={"d-inline-block"} style={{listStyle: "none"}}>
                                        {devices}
                                    </ul>
                                    {devEditRights &&
                                        <Button text={"Attach"} class={"btn btn-primary float-end me-3"}
                                                id={"device-modal-btn"}
                                                disabled={!devEditRights}
                                                onClick={() => {
                                                    if (devEditRights) {
                                                        setManufactureId(0);
                                                        setDeviceTypeId(0);
                                                        setDeviceCatId(0);
                                                        setListenDevice("");
                                                        setListenData([]);
                                                        setListenList([]);
                                                        setShowDeviceList(false);
                                                        document.getElementById("listen-container")?.classList?.remove("d-none");
                                                        formCheck("pendantForm", "pendantSubmit")
                                                        showModal("pendantmodal");
                                                    }
                                                }}/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={tabItem === "reporting" ? "d-block" : "d-none"}>
                            <div className={"card"} style={{minWidth: "500px"}}>
                                <div className={"card-header d-flex justify-content-between"}>
                                    <div>
                                        <h3 className={"card-header-title d-inline-block"}
                                            style={{minWidth: "80px"}}>Reporting</h3>
                                        <div className={"d-none"} style={{display: "inline-block"}}
                                             id={"reporting-header-data"}>
                                            <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                {!isNaN(hourData?.avgValue) ? hourData.avgValue : "?"} Alert/Week
                                            </h3>
                                            <h3 className={"mx-4 my-0 d-inline-block text-success"}>
                                                {!isNaN(weekData?.avgValue) ? weekData.avgValue : "?"} Avg/Resp
                                            </h3>
                                        </div>
                                    </div>
                                    <div>
                                        <i className={"fe fe-minus as-btn collapse-toggle p-2"}
                                           id={"reporting-card-btn"}
                                           onClick={() => {
                                               toggleCollapse("reporting-card-btn", "reporting-card")
                                               document.getElementById("reporting-header-data").classList.toggle("d-none");
                                           }}></i>
                                    </div>
                                </div>
                                <div className={"card-body collapse collapse-content show"} id={"reporting-card"}>
                                    <div className={"row"}>
                                        <Link to={"/reports/alerts/run"} onClick={() => {
                                            setResFilters(dData, residentId, `${firstName} ${lastName}`);
                                        }}>
                                            <Button text={"Run Detailed Report"}
                                                    class={"btn btn-primary mb-4 float-end"}
                                                    icon={true} iconClass={"fe fe-arrow-right-circle float-end ms-3"}/>
                                        </Link>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"chart-container w-50 text-end align-top d-inline-block"}>
                                            {hourDataLoaded
                                                ?
                                                (<div className={"chart-container"}>
                                                    <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                            id={"perhour"}></canvas>
                                                </div>)
                                                :
                                                (<div className={"text-center"}>
                                                    <Button text={""} class={"border-0 bg-transparent btn"}
                                                            spinner={true}></Button>
                                                </div>)
                                            }
                                        </div>
                                        <div className={"chart-container w-50 text-center align-top d-inline-block"}>
                                            {weekDataLoaded
                                                ?
                                                (<div className={"chart-container"}>
                                                    <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                            id={"perweek"}></canvas>
                                                </div>)
                                                :
                                                (<div className={"text-center"}>
                                                    <Button text={""} class={"border-0 bg-transparent btn"}
                                                            spinner={true}></Button>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                    <div className={"row mt-5"}>
                                        <div className={"w-50 px-4"}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead>
                                                <tr>
                                                    <th className={"table table-header w-auto py-1"} colSpan={"4"}>
                                                        Event Reason Breakdown
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className={"table table-header w-auto py-1"}>
                                                        Reason
                                                    </th>
                                                    <th className={"table table-header w-auto py-1"}>
                                                        Total
                                                    </th>
                                                    <th className={"table table-header w-auto py-1"}>
                                                        % Total Volume
                                                    </th>
                                                    <th className={"table table-header w-auto py-1"}>
                                                        Average Response (min)
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className={"ps-1 fw-bold"}>Total Events</td>
                                                    <td className={"ps-1 fw-bold text-center"}>
                                                        {reasonData?.count ? reasonData.count : 0}
                                                    </td>
                                                    <td className={"ps-1 text-right"}></td>
                                                    <td className={"ps-1 fw-bold text-center"}>
                                                        {reasonData?.avg_response_time ? reasonData.avg_response_time : 0.0}
                                                    </td>
                                                </tr>
                                                {reasonRows}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={"w-50"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover d-sm-table"}>
                                                <thead className={"sticky-table-head"} style={{top: 0, zIndex: 1}}>
                                                {eventTableHeaders}
                                                </thead>
                                                <tbody>
                                                {eventTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={tabItem === "wellness" ? "d-block" : "d-none"}>
                            <div className={"card"} style={{minWidth: "500px"}}>
                                <div className={"card-header d-flex justify-content-between"}>
                                    <div>
                                        <h3 className={"card-header-title d-inline-block"}
                                            style={{minWidth: "80px"}}>Wellness</h3>
                                    </div>
                                    <div>
                                        <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"wellness-card-btn"}
                                           onClick={() => toggleCollapse("wellness-card-btn", "wellness-card")}></i>
                                    </div>
                                </div>
                                <div className={"card-body collapse collapse-content show"} id={"wellness-card"}>
                                    <div className={"ms-4"}>
                                        <div className={"d-block"}>
                                            <div className={"d-inline-block align-top me-4"}
                                                 style={{minWidth: "125px"}}>
                                                Careflow Plan:
                                            </div>
                                            <div className={"d-inline-block"}>
                                                {careflow && careflow?.length > 0 ? careflow : "No Careflow"}
                                            </div>
                                        </div>
                                        <div className={"d-block mt-3"}>
                                            <div className={"d-inline-block align-top me-4"}
                                                 style={{minWidth: "125px"}}>
                                                Check-ins:
                                            </div>
                                            <div className={"d-inline-block"}>
                                                {checkin && checkin?.length > 0 ? checkin : "No Check-in Required"}
                                            </div>
                                        </div>
                                        <div className={"d-block mt-3"}>
                                            <div className={"d-inline-block align-top me-4"}
                                                 style={{minWidth: "125px"}}>
                                                Rounds:
                                            </div>
                                            <div className={"d-inline-block"}>
                                                {rounds && rounds?.length ? rounds : "No Rounds Required"}
                                            </div>
                                        </div>
                                        <div className={"d-block mt-3"}>
                                            <div className={"d-inline-block align-top me-4"}
                                                 style={{minWidth: "125px"}}>
                                                EHR:
                                            </div>
                                            <div className={"d-inline-block"}>
                                                {ehs
                                                    ? <a href={ehsurl} rel={"noreferrer"} target="_blank">
                                                        {ehs}<i className={"fe fe-external-link ms-2"}></i></a>
                                                    : "None"}
                                            </div>
                                        </div>
                                        <div className={"d-block mt-3"}>
                                            <div className={"d-inline-block align-top me-4"}
                                                 style={{minWidth: "125px"}}>EHR Id:
                                            </div>
                                            <div className={"d-inline-block"}><p id={"ehr-id-static"}
                                                                                 style={{minWidth: "100px"}}>
                                                {resBasicData?.ehr_external_id != null ? resBasicData.ehr_external_id : "N/A"}
                                            </p>
                                                <input className={"ms-4 d-none form-control form-control-sm"}
                                                       style={{height: "30px", width: "256px"}} id={"ehr-id-edit"}
                                                       readOnly={true}
                                                       value={resBasicData?.ehr_external_id != null ? resBasicData.ehr_external_id : ""}
                                                       pattern={"^[0-9a-zA-Z_\\- ]+$"} onKeyUp={(e) => {
                                                    setEhrId(e.target.value)
                                                }}/>
                                                {ehs && editRights
                                                    ? <i className={"fe fe-edit as-btn-1x"} onClick={() => {
                                                        document.getElementById("ehr-id-static").classList.toggle("d-none");
                                                        document.getElementById("ehr-id-edit").classList.toggle("d-none");
                                                    }}></i>
                                                    : <></>
                                                }
                                                {editRights &&
                                                    <>
                                                        <Button type={"button"} class={"btn btn-primary d-none ms-3"}
                                                                disabled={true} spinner={true} text={"Loading"}
                                                                id={"loadEhrSubmit"}/>
                                                        <Button text={"Save"} class={"d-none btn btn-primary ms-3"}
                                                                id={"ehrSubmit"}
                                                                onClick={() => performEhrSubmit()}/>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isMobileActive
                            ?
                            <div className={tabItem === "mobile" ? "d-block" : "d-none"}>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>Mobile</h3>
                                            <div className={"d-none"} style={{display: "inline-block"}}
                                                 id={"mobile-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}></h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-minus as-btn collapse-toggle p-2"}
                                               id={"mobile-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("mobile-card-btn", "mobile-card");
                                                   document.getElementById("mobile-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content show"} id={"mobile-card"}>
                                        {cognitoId
                                            ?
                                            <>
                                                <h5 className={"d-inline-block mb-3"}>Username: </h5><span
                                                className={"ms-3"}>{username}</span>
                                                <h5 className={"d-inline-block mb-3"}>Phone: </h5><span
                                                className={"ms-3"}>{mobilePhone}</span>
                                                <h5 className={"d-inline-block mb-3"}>Email: </h5><span
                                                className={"ms-3"}>{mobileEmail}</span>
                                                <form id={"addMobileForm"} className={"was-validated d-none"}>
                                                    <div>

                                                    </div>
                                                </form>
                                                {editRights &&
                                                    <>
                                                        <Button text={"Reset Password"} type={"button"}
                                                                class={"btn btn-primary d-block"}
                                                                id={"passBtn"}
                                                                icon={true} iconClass={"fe fe-lock me-2"}
                                                                onClick={() => {
                                                                    formClear("passForm", "passSubmit");
                                                                    showModal("passmodal");
                                                                }}/>
                                                        <Button text={"Remove Mobile"} type={"button"}
                                                                class={"btn btn-danger d-block"}
                                                                id={"deleteBtn"}
                                                                onClick={() => showModal("mobileconfirmmobile")}/>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className={"mb-3"}>
                                                    {editRights &&
                                                        <>
                                                            <Button text={"Set Up Mobile"} class={"btn btn-primary"}
                                                                    id={"add-mobile-btn"}
                                                                    disabled={!editRights}
                                                                    onClick={() => {
                                                                        document.getElementById("addMobileForm").classList.remove("d-none");
                                                                        document.getElementById("add-mobile-cancel-btn").classList.remove("d-none");
                                                                        document.getElementById("add-mobile-btn").classList.add("d-none");
                                                                        document.getElementById("addMobileSubmit").classList.remove("d-none");
                                                                        formCheck("addMobileForm", "addMobileSubmit");
                                                                    }}/>
                                                            <Button text={"Cancel"}
                                                                    class={"btn btn-secondary d-none me-3"}
                                                                    id={"add-mobile-cancel-btn"} onClick={() => {
                                                                document.getElementById("add-mobile-btn").classList.remove("d-none");
                                                                document.getElementById("add-mobile-cancel-btn").classList.add("d-none");
                                                                document.getElementById("addMobileForm").classList.add("d-none");
                                                                document.getElementById("addMobileSubmit").classList.add("d-none");
                                                                formClear("addMobileForm", "addMobileSubmit");
                                                            }}/>
                                                            <Button text={"Loading"} class={"btn btn-primary d-none"}
                                                                    id={"loadAddMobileSubmit"} spinner={true}
                                                                    disabled={true}/>
                                                            <Button text={"Submit"} class={"btn btn-primary d-none"}
                                                                    id={"addMobileSubmit"}
                                                                    onClick={() => {
                                                                        addMobileSubmit();
                                                                    }}/>
                                                        </>
                                                    }
                                                </div>
                                                <form id={"addMobileForm"} className={"was-validated d-none"}
                                                      style={{maxWidth: "400px"}}>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"text"} className={"form-control"} id={"username"}
                                                               onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}
                                                               required={true} pattern={"^[0-9a-zA-Z_@-]+$"}/>
                                                        <label htmlFor={"username"}>Username</label>
                                                        <div className={"invalid-feedback"}>
                                                            Please enter a valid username! No spaces allowed.
                                                        </div>
                                                    </div>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"password"} className={"form-control"}
                                                               id={"password"}
                                                               required={true} pattern={"[ -~]{6,}$"}
                                                               onKeyUp={(e) => {
                                                                   document.getElementById("password2").pattern = e.target.value;
                                                                   formCheck("addMobileForm", "addMobileSubmit");
                                                               }}/>
                                                        <label htmlFor={"password"}>Password</label>
                                                        <div className={"invalid-feedback"}>
                                                            Please enter a valid password! 6 Character minimum.
                                                        </div>
                                                    </div>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"password"} className={"form-control"}
                                                               id={"password2"}
                                                               required={true}
                                                               onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}/>
                                                        <label htmlFor={"password2"}>Confirm Password</label>
                                                        <div className={"invalid-feedback"}>
                                                            Confirm password must match previous password field.
                                                        </div>
                                                    </div>
                                                    <div className={"my-3"}>
                                                        <input type={"checkbox"} className={"form-check-input mx-3"}
                                                               id={"mobile-fillin-toggle"} onClick={(e) => {
                                                            let mp, me;
                                                            mp = document.getElementById("mobile-phone-add");
                                                            me = document.getElementById("mobile-email-add");
                                                            if (e.target.checked) {
                                                                mp.value = phone;
                                                                me.value = email.toLowerCase();
                                                                mp.readOnly = true;
                                                                me.readOnly = true;
                                                            } else {
                                                                mp.readOnly = false;
                                                                me.readOnly = false;
                                                                mp.value = "";
                                                                me.value = "";
                                                            }
                                                            formCheck("addMobileForm", "addMobileSubmit");
                                                        }}/>
                                                        <label htmlFor={"mobile-fillin-toggle"}>Use Profile Contact
                                                            Info</label>
                                                    </div>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"text"} className={"form-control"}
                                                               id={"mobile-phone-add"}
                                                               onKeyUp={() => {
                                                                   formCheck("addMobileForm", "addMobileSubmit")
                                                               }}/>
                                                        <label htmlFor={"mobile-phone-add"}>Phone Number</label>
                                                        <div className={"invalid-feedback"}>
                                                            Enter a valid phone number.
                                                        </div>
                                                    </div>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"text"} className={"form-control"}
                                                               id={"mobile-email-add"}
                                                               pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}
                                                               onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}/>
                                                        <label htmlFor={"mobile-email-add"}>Email Address</label>
                                                        <div className={"invalid-feedback"}>
                                                            Enter a valid email address.
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div> : <></>}
                    </div>
                    : <div className={"container-fluid"}><h5>Loading...</h5></div>
                }
            </div>
            <div className={"modal fade"} id={"relocatemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticRelocateLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <form id={"relocateForm"} className={"was-validated"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticRelocateLabel"}>Relocate Resident</h2>
                                <button type={"button"} className={"btn-close"} id={"relocateClose"}
                                        data-bs-dismiss={"modal"} aria-label={"Close"}/>
                            </div>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} id={"area-edit"} defaultValue={areaId}
                                            onChange={() => formCheck("relocateForm", "relocateSubmit")}>
                                        {locationOptions}
                                    </select>
                                    <label htmlFor={"area-edit"}>Server</label>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelRelocateBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadRelocateSubmit"}/>
                                <Button type={"button"} text={"Save"} class={"btn btn-primary ms-3"}
                                        id={"relocateSubmit"} disabled={!editRights}
                                        onClick={() => relocateSubmit()}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"imageuploadmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticImageUploadLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticImageUploadLabel"}>Image Upload</h2>
                            <button type={"button"} className={"btn-close"} id={"imageUploadClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Selected file: {newFileName}</p>
                            <input className={"d-none"} id={"image-upload"} type={"file"} accept={"image/*"}
                                   onChange={() => handleFileUpload()}/>
                            <Button type={"button"} text={"Set"} class={"btn btn-outline-primary float-start me-4"}
                                    onClick={() => {
                                        document.getElementById("image-upload").click()
                                    }}/>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} id={"cancelImageUploadBtn"}
                                    data-bs-dismiss={"modal"}>Cancel
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadImageUploadSubmit"}/>
                            <Button type={"button"} text={"Save"} class={"btn btn-primary ms-3"}
                                    id={"imageUploadSubmit"} onClick={() => imageSubmit()}
                                    disabled={newFileName === ""}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Device</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantClose"}
                                    data-bs-dismiss={"modal"}
                                    aria-label={"Close"} onClick={() => setListening(false)}/>
                        </div>
                        <form id={"pendantForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_manufacture"}
                                            onChange={(e) => {
                                                setManufactureId(parseInt(e.target.value));
                                                if (e.target?.value?.toString() === "6") {
                                                    setSerialPattern("^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$");
                                                } else if (e.target?.value?.toString() === "4") {
                                                    console.log("SERIAL")
                                                    setSerialPattern("^([0-9A-Fa-f]{2}[:\\-]){5}([0-9A-Fa-f]{2})|[0-9A-Fa-f]{12}$");
                                                } else {
                                                    setSerialPattern("^[0-9]+$");
                                                }
                                                setDeviceCatId(0);
                                                setDeviceTypeId(0);
                                                setModelId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {manufactureOptions}
                                    </select>
                                    <label htmlFor={"device_manufacture"}>Device Manufacturer</label>
                                </div>
                                <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_category"}
                                            onChange={(e) => {
                                                setDeviceCatId(parseInt(e.target.value));
                                                setDeviceTypeId(0);
                                                setModelId(0);
                                                formCheck("pendantForm", "pendantSubmit")
                                            }}>
                                        <option value={""}></option>
                                        {deviceCatOptions}
                                    </select>
                                    <label htmlFor={"device_category"}>Device Category</label>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                        <select className={"form-select"} required={true}
                                                value={undefined} id={"device_type_id"}
                                                onChange={(e) => {
                                                    setDeviceTypeId(parseInt(e.target.value));
                                                    setDeviceTypeName(e.target[e.target.selectedIndex].innerText);
                                                    if (manufactureId.toString() === "6") {
                                                        if (e.target.value.toString() === "4") {
                                                            setSerialPattern("^[0-9a-zA-z]+$");
                                                            document.getElementById("serialNum").pattern = "^[0-9a-zA-z]+$";
                                                        } else {
                                                            setSerialPattern("^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$");
                                                            document.getElementById("serialNum").pattern = "^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$";
                                                        }
                                                    }
                                                    formCheck("pendantForm", "pendantSubmit");
                                                }}>
                                            <option value={""}></option>
                                            {deviceTypeOptions}
                                        </select>
                                        <label htmlFor={"device_type_id"}>Device Type</label>
                                        <div className={deviceImg ? "select-img-div" : "select-img-div-nohover"}>
                                            {deviceImg && <img
                                                src={`${process.env.PUBLIC_URL}/static/image/${deviceImg}`}
                                                alt={""}
                                                className={"mx-auto"}
                                                style={{
                                                    width: "inherit",
                                                    height: "inherit",
                                                    padding: "0 10px 6px 0"
                                                }}/>}
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block ms-auto"} style={{width: "30%"}}>
                                        <select className={"form-select"} id={"modelId"} value={modelId}
                                                onChange={(e) => {
                                                    setModelId(parseInt(e.target.value));
                                                    formCheck("pendantForm", "pendantSubmit");
                                                }}>
                                            {modelOptions}
                                        </select>
                                        <label htmlFor={"modelId"}>Model</label>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"description"}>Description (optional)</label>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={`form-floating mb-3 w-75 ${showDeviceList && "d-none"}`}
                                         id={"serialToggle"}>
                                        <input type={"text"} className={"form-control"} id={"serialNum"}
                                               required={!showDeviceList}
                                               onKeyUp={(e) => formCheckWithElement("pendantForm", "pendantSubmit", e, resDeviceData)}
                                               pattern={serialPattern}/>
                                        <label htmlFor={"serialNum"}>Serial Number</label>
                                    </div>
                                    <div className={`form-floating mb-3 w-75 ${!showDeviceList && "d-none"}`}
                                         id={"serial2Toggle"}>
                                        <select className={"form-select"} id={"serialNum2"} required={showDeviceList}
                                                onClick={(e) => {
                                                    let tmp = e.target.value.split(",");
                                                    setListenDevice(tmp[0]);
                                                    setListenPCode(parseInt(tmp[1]));
                                                }}>
                                            {listenList}
                                        </select>
                                        <label htmlFor={"serialNum2"}>Serial Numbers</label>
                                    </div>
                                    <div className={"mt-3 mt-3 ms-3 d-none"} id={"listen-container-main"}>
                                        <Button text={"Auto Listen"} id={"listen-btn-1"}
                                                class={`btn ${listenEnabled ? "btn-primary" : "btn-danger"} m-auto ${showDeviceList && "d-none"}`}
                                                disabled={!listenEnabled} onClick={() => {
                                            setListening(true);
                                            setShowDeviceList(true);
                                            publishListen()
                                        }}/>
                                        <Button text={"Stop Listen"} id={"listen-btn-2"}
                                                class={`btn ${listening ? "btn-success" : "btn-warning"} m-auto ${!showDeviceList && "d-none"}`}
                                                disabled={!listenEnabled} onClick={() => {
                                            setListening(false);
                                            setShowDeviceList(false);
                                        }}/>
                                    </div>
                                </div>
                                <div className={`list-group-item ${!showAlarmBehaviors && 'd-none'}`}
                                     id={"behavior-heading-container"}>
                                    <h4 className={"mb-4 text-center"}>Alarm Behavior</h4>
                                    {useOptions}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPendantBtn"}
                                            data-bs-dismiss={"modal"}
                                            onClick={() => {
                                                formClear("pendantForm", "pendantSubmit")
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPendantSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantSubmit"}
                                            onClick={() => pendantSubmit(true)}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticPendantConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticPendantConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Device is already linked to {assignedRes} Do you wish to re-assign this device?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"pendantConfirmCancelBtn"}
                                    onClick={() => swapModal("pendantConfirmClose", "pendantmodal")}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadPendantConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantConfirmSubmit"}
                                    onClick={() => pendantSubmit(false)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"mobileconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticMobileConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticMobileConfirmLabel"}>Confirm Removal</h2>
                            <button type={"button"} className={"btn-close"} id={"mobileConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to remove this resident from access to the resident mobile app?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"mobileConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadMobileConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"mobileConfirmSubmit"}
                                    onClick={() => mobileDeleteSubmit()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deviceconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"deviceConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to permanently detach this device from {firstName} {lastName}?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"deviceCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadDeviceConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deviceConfirmSubmit"}
                                    onClick={() => removeDevice(deviceToRemove)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                            <button type={"button"} className={"btn-close"} id={"passClose"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"passForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordR"}
                                           onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"}/>
                                    <label htmlFor={"passwordR"}>New Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordCR"}
                                           onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"}/>
                                    <label htmlFor={"passwordCR"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Password does not match.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPassBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"}
                                            onClick={() => passSubmit()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deleteconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Delete</h2>
                            <button type={"button"} className={"btn-close"} id={"deleteConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to permanently delete this resident? The resident will be removed
                                from their assigned location and all
                                devices will no longer be attached to this resident. The resident will no longer show in
                                the system or on any dashboards/reports.</p>
                            <p>If you wish to retain the residents current and historical data, move out the resident
                                instead.</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"deleteConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadDeleteConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deleteConfirmSubmit"}
                                    onClick={() => deletePerform()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"moveoutconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Moving Resident Out</h2>
                            <button type={"button"} className={"btn-close"} id={"moveOutConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to move out this resident? The resident will no longer be assigned
                                to their current location and all
                                devices will no longer be attached. The resident's detailed historical activity will
                                still show in reporting (excluding the main dashboards).</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"moveOutConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadMoveOutConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"moveOutConfirmSubmit"}
                                    onClick={() => moveOutPerform()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"moveinconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Move Resident Back In?</h2>
                            <button type={"button"} className={"btn-close"} id={"moveInConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <form id={"moveInForm"} className={"was-validated"}>
                                <p>To move this resident back in and make them active, please assign to an area.</p>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} defaultValue={undefined} required={true}
                                            id={"locationMoveIn"}
                                            onChange={() => formCheck("moveInForm", "moveInConfirmSubmit")}>
                                        <option value={""}></option>
                                        {locationOptions}
                                    </select>
                                    <label htmlFor={"locationId"}>Area</label>
                                </div>
                            </form>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"moveInConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadMoveInConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"moveInConfirmSubmit"}
                                    onClick={() => moveInPerform()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addnotemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticAddNoteBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticAddNoteBackdropLabel"}>Add
                                Note</h2>
                            <button type={"button"} className={"btn-close"} id={"addNoteClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"addNoteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                {dData?.event_notes_preform
                                    ? <div className={"form-floating d-block"}>
                                        <select className={"form-select form-select my-2"} id={"note"}
                                                required={true}
                                                onChange={(e) => {
                                                    if (e.target.value === "0") {
                                                        document.getElementById("freeform").classList.remove("d-none");
                                                        document.getElementById("note-freeform").required = true;
                                                    } else {
                                                        document.getElementById("note-freeform").required = false;
                                                        document.getElementById("freeform").classList.add("d-none");
                                                    }
                                                    formCheck("addNoteForm", "addNoteSubmit");
                                                }}>
                                            {prenoteOptions}
                                        </select>
                                        <label htmlFor={"note"} className={"form-label"}>Note</label>
                                    </div>
                                    :   <div className={"my-2 form-floating"}>
                                            <textarea className={"form-control pt-5"} id={"note"} required={true}
                                                      style={{height: "125px"}}
                                                      onKeyUp={() => formCheck("addNoteForm", "addNoteSubmit")}>

                                            </textarea>
                                            <label htmlFor={"note"} className={"form-label"}>Note</label>
                                            <div className={"invalid-feedback"}>
                                                Note required
                                            </div>
                                        </div>
                                }
                                <div className={"my-2 form-floating d-none"} id={"freeform"}>
                                    <textarea className={"form-control pt-5"} id={"note-freeform"}
                                              style={{height: "125px"}}
                                              onKeyUp={() => formCheck("addNoteForm", "addNoteSubmit")}>
                                    </textarea>
                                    <label htmlFor={"note-freeform"} className={"form-label"}>Note</label>
                                    <div className={"invalid-feedback"}>
                                        Note required
                                    </div>
                                </div>
                                {noteTableLength > 0
                                    ? <>
                                        <hr/>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                                            {notesTableHeaders}
                                            </thead>
                                            <tbody id={"tBody"}>
                                            {noteTableRows}
                                            </tbody>
                                        </table>
                                    </>
                                    : <></>
                                }
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddNoteBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddNoteSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addNoteSubmit"}
                                            onClick={() => addNoteSubmit()}>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default ResidentProfile;